import {
  buildTextResources,
  CopyToClipoardHoverButton,
  IDataKit,
  isResourceKey,
  isSelectData,
  Localizer,
  mergeProperties,
  OptionsType,
  ResourceKeyReference,
  TextResource,
  useConstant,
  useLocalizationService,
  useLocalize
} from "@emibee/lib-app-common";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  DataGridPro,
  DataGridProProps,
  GridActionsCellItem,
  GridActionsColDef,
  GridApi,
  GridApiPro,
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  gridFilteredTopLevelRowCountSelector,
  GridFilterInputValueProps,
  GridFilterItem,
  GridFilterModel,
  GridFilterOperator,
  GridFooterContainer,
  GridLoadIcon,
  GridLocaleText,
  GridLogicOperator,
  GridRowId,
  GridRowParams,
  GridRowsProp,
  GridSlotProps,
  GridSlotsComponentsProps,
  GridSortDirection,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValidRowModel,
  useGridApiRef,
  useGridSelector
} from "@mui/x-data-grid-pro";
import { deDE } from "@mui/x-data-grid-pro/locales";
import { LicenseInfo } from "@mui/x-license";
import * as React from "react";
import { makeStyles } from "../../Theme";
import { useFormatters } from "../../tools/formatters";
import { getMultiSelectOperators } from "./MultiSelectOperator";
import { debounce, Theme } from "@mui/material";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { useDataGridViews } from "../view/useDataGridViews";

LicenseInfo.setLicenseKey(
  "0485357e1a5a5061de22c97448df93faTz0xMDIxMjYsRT0xNzYzNzE3MDAxMDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y"
);

interface StyleArgs {
  height?: string;
}

const useStyles = makeStyles<StyleArgs>()((theme, { height = "100%" }) => ({
  xGrid: {
    // height: "auto",
    height: height,
    display: "flex",
    marginBottom: theme.spacing(2)
  }
}));

// Export Constants
export const DataGridLoadIcon = GridLoadIcon;

// Export TypeAliases
export type DataGridRowsProp = GridRowsProp;
export type DataGridRowProp<R extends DataGridRowModel = any> = GridRowParams<R>;
export const DataGridActionsCellItem = GridActionsCellItem;

// export type DataGridCellValue = GridCellValue;
export type DataGridCellParams = GridCellParams;
export type DataGridXGridProps = DataGridProProps;
export type DataGridFilterItem = GridFilterItem;
export type DataGridFilterOperator = GridFilterOperator;
export type DataGridFilterInputValueProps = GridFilterInputValueProps;
export type DataGridRowModel = GridValidRowModel;
export const DataGridLogicOperator = GridLogicOperator;
export type DataGridInitialState = GridInitialStatePro;
export type DataGridApiPro = GridApiPro;

// Export Interfaces
export interface DataGridFilterOptionsInputProps extends DataGridFilterInputValueProps {
  options?: OptionsType;
  label?: string;
  localize?: Localizer;
}
export type DataGridColumnDef<T extends DataGridRowModel = any, V = any, F = V> = GridColDef<T, V, F> & {
  options?: OptionsType;
};

export interface DataGridFilterModel extends GridFilterModel {
  // options?: OptionsType;
}

export interface DataGridSortModel extends GridSortModel {}
export interface DataGridColumnVisibilityModel extends GridColumnVisibilityModel {}

const dataGridTextResources = buildTextResources({
  scope: "Controls",
  namespace: "DataGrid",
  resources: {
    footerTotalLabel: "Total:",
    footerTotalPartText: "of"
  }
});

type ColumnName<T> = Extract<keyof T, string>;

interface MHCellParams<T, FieldT extends keyof T> {
  row: T;
  value: T[FieldT];
  column: DataGridColumnDef;
  // formattedValue?: T[FieldT];
}

interface DataGridColumn<T, FieldT extends keyof T>
  extends Omit<Partial<GridActionsColDef>, "field" | "valueFormatter" | "valueGetter" | "renderCell"> {
  customName?: string;
  valueGetter?: (params: MHCellParams<T, FieldT>) => any;
  valueFormatter?: (value?: T[FieldT]) => any;
  renderCell?: (params: MHCellParams<T, FieldT>) => React.ReactElement;
  hoverCopy?: boolean;
}

interface IDataGridColumnPropsBuilder<T extends DataGridRowModel, FieldT extends keyof T>
  extends IDataGridColumnBuilder<T> {
  props: (opts: DataGridColumn<T, FieldT>) => IDataGridColumnBuilder<T>;
}

export interface DataGridDefinition<T extends DataGridRowModel = any> {
  columns: DataGridColumnDef<T>[];
  filterModel?: DataGridFilterModel;
  sortModel?: DataGridSortModel;
  columnVisibilityModel?: DataGridColumnVisibilityModel;
  onFilterModelChange?: (model: GridFilterModel) => void;
  showExportButton?: boolean;
  getRowId?: (row: T) => string | number;
  // dateRangeFilter?: { field: string; preset?: DateRangeFilterPreset };
  customToolbarComponents?: React.ReactNode;
  customFooterComponents?: React.ReactNode;
  getDetailPanelContent?: (params: GridRowParams<T>) => React.ReactNode;
  getDetailPanelHeight?: (params: GridRowParams) => number | "auto";
  getTreeDataPath?: (row: T) => string[];
}

export interface DateRangeFilterPreset {
  middleOfDaysRange?: number;
  pastDaysAndFuture?: number;
  latestDaysTilNow?: number;
  daysFromNow?: number;
  thisMonth?: boolean;
  range?: [Date, Date];
}

export interface IDataGridColumnBuilder<T extends DataGridRowModel = any> {
  initialSort: (column: ColumnName<T>, direction?: GridSortDirection) => IDataGridColumnBuilder<T>;
  idColumn: (column: ColumnName<T>) => IDataGridColumnBuilder<T>;
  // dateRangeFilter: (column: ColumnName<T>, preset?: DateRangeFilterPreset) => IDataGridColumnBuilder<T>;
  selectColumns: (...columns: ColumnName<T>[]) => IDataGridColumnBuilder<T>;
  selectColumnsBL: (...columns: ColumnName<T>[]) => IDataGridColumnBuilder<T>;
  visibleColumns: (...columns: ColumnName<T>[]) => IDataGridColumnBuilder<T>;
  column: <FieldT extends Extract<keyof T, string>>(
    name?: FieldT,
    label?: string
  ) => Pick<IDataGridColumnPropsBuilder<T, FieldT>, "build" | "column" | "props">;
  customColumn: (
    name: string,
    colIndex?: number,
    label?: string | ResourceKeyReference
  ) => Pick<IDataGridColumnPropsBuilder<T, any>, "build" | "column" | "props">;
  build: () => DataGridDefinition<T>;
}
export function buildDataGrid<T extends DataGridRowModel = any>(dataKit: IDataKit<T>, localize: Localizer) {
  let columns: DataGridColumnDef[] | undefined = undefined;
  let customFieldIdx = 1;
  let colBuilder: IDataGridColumnBuilder<T>;
  let columnVisibilityModel: DataGridColumnVisibilityModel | undefined = undefined;
  let pendingCol: DataGridColumnDef | undefined = undefined;
  const sortModel: DataGridSortModel = [];
  let filterModel: DataGridFilterModel | undefined = undefined;
  let getRowId: (row: any) => string | number;
  // let dateRangeFilter: DataGridDefinition["dateRangeFilter"] | undefined = undefined;

  const ensureColumns = () => {
    if (!columns) columns = selectColumns(dataKit, localize);
    return columns;
  };

  // const getColumn = (name: string) => {
  //   const col = ensureColumns().find(col => col.field === name);
  //   if (!col) throw new Error("Column not found: " + name);
  //   else return col;
  // };

  const setColumn = (col: DataGridColumnDef, colIdx?: number) => {
    const cols = ensureColumns();
    if (!col.headerName) {
      delete col.headerName;
    }
    if (!col.renderCell) {
      delete col.renderCell;
    }
    let idx = cols.findIndex(c => c.field === col.field);
    if (idx < 0) {
      idx = cols.push(col);
    } else {
      const colDef = cols[idx];

      cols[idx] = mergeProperties(colDef, col);
      // console.log("setColumn", colDef, "+", col, "=", cols[idx]);
    }

    if (colIdx !== undefined && colIdx !== idx) {
      // move
      const delCol = cols.splice(idx, 1)[0];
      cols.splice(colIdx, 0, delCol);
    }
  };
  const handlePending = () => {
    if (pendingCol) {
      if (!pendingCol.field) throw new Error("Columns with no name and no props are not supported.");

      setColumn(pendingCol);
    }
  };

  const buildDefinition = (): DataGridDefinition => {
    handlePending();
    return {
      columns: ensureColumns(),
      sortModel,
      filterModel,
      columnVisibilityModel,
      getRowId
      // dateRangeFilter
    };
  };

  const handleColumns = (name: string, colIndex?: number, label?: string | ResourceKeyReference) => {
    handlePending();
    pendingCol = {
      field: name as string,
      headerName: localize(label) as string
    };
    return {
      props: (opts: DataGridColumn<T, any>) => {
        pendingCol = undefined;
        const {
          customName,
          headerName = localize(label) as string,
          valueFormatter,
          hoverCopy,
          renderCell,
          valueGetter,
          ...colDef
        } = opts;
        setColumn(
          {
            ...colDef,
            headerName,
            field: name ?? customName ?? `custom${customFieldIdx++}`,
            renderCell:
              renderCell ?? (hoverCopy ? p => renderHoverCopy(p.value, p.colDef.align === "left") : undefined),
            valueFormatter: valueFormatter ? val => valueFormatter((val as any) ?? undefined) : undefined,
            valueGetter: valueGetter ? (value, row, column) => valueGetter({ value, row, column }) : undefined
          } as DataGridColumnDef,
          colIndex
        );

        return colBuilder;
      },
      column: colBuilder.column,
      build: colBuilder.build
    };
  };

  colBuilder = {
    initialSort: (field: ColumnName<T>, sort: GridSortDirection = "asc") => {
      sortModel.push({ field, sort });
      return colBuilder;
    },
    // dateRangeFilter: (field: ColumnName<T>, preset?: DateRangeFilterPreset) => {
    //   dateRangeFilter = { field, preset };
    //   return colBuilder;
    // },
    idColumn: (field: ColumnName<T>) => {
      getRowId = row => row && row[field];
      return colBuilder;
    },
    selectColumns: (...selectedColumns: ColumnName<T>[]) => {
      columns = selectColumns(dataKit, localize, selectedColumns);
      return colBuilder;
    },
    selectColumnsBL: (...blacklistedColumns: ColumnName<T>[]) => {
      columns = selectColumns(dataKit, localize, filterBlacklistDatakit(dataKit, blacklistedColumns));
      return colBuilder;
    },
    visibleColumns: (...visibleColumns: ColumnName<T>[]) => {
      if (!columns) throw new Error("Select columns first before set visibility");

      const visibleCols = visibleColumns.map(field => columns!.find(col => col.field === field)) as DataGridColumnDef[];
      const hiddenCols = columns.filter(col => !visibleCols.includes(col));

      // visibility
      columnVisibilityModel = hiddenCols.reduce((model, col) => {
        model[col.field] = false;
        return model;
      }, {} as DataGridColumnVisibilityModel);

      // order
      columns = [...visibleCols, ...hiddenCols];
      return colBuilder;
    },
    column: <FieldT extends Extract<keyof T, string>>(name?: FieldT, label?: string | ResourceKeyReference) =>
      handleColumns(name as string, undefined, label),
    customColumn: handleColumns,
    build: buildDefinition
  };

  return colBuilder;
}

export function useDataGridBuilder<T extends {}>(
  dataKit: IDataKit<T>,
  builderFn: (args: {
    builder: IDataGridColumnBuilder<T>;
    localize: Localizer;
    formatters: ReturnType<typeof useFormatters>;
  }) => void
) {
  const localize = useLocalize();
  const formatters = useFormatters();
  return useConstant(() => {
    const builder = buildDataGrid(dataKit, localize);
    builderFn({ builder, localize, formatters });
    return builder.build();
  });
}

function filterBlacklist<ListT, BlackLT>(
  list: ListT[],
  bl: BlackLT[],
  matchFn: (itemL: ListT, itemBl: BlackLT) => boolean
): ListT[] {
  return list.filter(itemL => !bl.some(itemBl => matchFn(itemL, itemBl)));
}

function filterBlacklistDatakit(dataKit: IDataKit<any>, columns: string[]) {
  return filterBlacklist(dataKit.fields, columns, (field, column) => field.name === column).map(field => field.name);
}

function findField(name: string, dataKit: IDataKit<any>) {
  return dataKit.fields.find(f => f.name === name);
}

function selectColumns(dataKit: IDataKit<any>, localize: Localizer | undefined, selection?: string[]) {
  let fields = dataKit.fields;
  if (selection) {
    fields = selection.map(s => findField(s, dataKit)!);
  }
  return fields.map<DataGridColumnDef>(f => {
    const colDef: DataGridColumnDef = {
      field: f.name,
      type: f.type,
      align: !f.type || f.type === "string" ? "left" : f.type === "boolean" ? "center" : "right",
      headerName: localize ? (localize(f.res) as string) : f.res.fallback,
      valueGetter: f.valueGetter ? (value, row) => f.valueGetter!(row) : undefined,
      valueFormatter: f.valueFormatter
        ? val => f.valueFormatter!(val)
        : f.options
        ? OptionsFormatter(f.options, localize)
        : undefined
    };
    if (f.options) {
      colDef.filterOperators = getMultiSelectOperators(f.options, localize);
      colDef.options = f.options;
    }
    if (!colDef.valueGetter && (f.type === "date" || f.type === "dateTime")) {
      colDef.valueGetter = val => (val ? new Date(val as number) : val);
    }
    return colDef;
  });
}

function OptionsFormatter(options: OptionsType, localize: Localizer | undefined) {
  let locOptions: any | undefined = undefined;
  if (isSelectData(options)) {
    locOptions = options.reduce((p, c) => {
      p[c.value.toString()] = localize && isResourceKey(c.label) ? localize(c.label) : c.label ?? c.value;
      return p;
    }, {} as any);
  }
  // return (params: DataGridCellParams) => {
  // Todo: @michi, was anstelle von any?

  return (val: unknown) => {
    if (locOptions && val !== undefined && val !== null) {
      if (Array.isArray(val)) {
        return val.map(v => locOptions[v.toString()]);
      } else {
        return locOptions[val.toString()];
      }
    } else {
      return val;
    }
    // console.log("OptionsFormatter", options, val, locOptions);
    // return locOptions && val !== undefined ? locOptions[val.toString()] : val;
  };
}

export interface DataGridPopulateOptionsFilter {
  column: string;
  preset?: any[];
}

export interface DataGridApi {
  quickfilter: (filter: string) => void;
  startEdit: (id: GridRowId, field: string) => void;
  stopEdit: (id: GridRowId, field: string) => void;
  exportState: () => DataGridInitialState;
  restoreState: (state: DataGridInitialState) => void;
}

export interface DataGridProps extends DataGridDefinition {
  rows: DataGridRowsProp;
  onRowClick?: DataGridXGridProps["onRowClick"];
  onCellClick?: DataGridXGridProps["onCellClick"];
  onCellEditStart?: DataGridXGridProps["onCellEditStart"];
  onCellEditStop?: DataGridXGridProps["onCellEditStop"];
  processRowUpdate?: DataGridXGridProps["processRowUpdate"];
  onQuickFilterChange?: (val?: string) => void;
  dataGridKey?: string;
  loading?: boolean;
  rowHeight?: number;
  maxVisibleEntries?: boolean | number;
  api?: React.MutableRefObject<DataGridApi | undefined>;
}

export function DataGrid(props: DataGridProps) {
  const {
    filterModel,
    columnVisibilityModel,
    columns,
    rows,
    sortModel,
    api,
    onFilterModelChange,
    onQuickFilterChange,
    dataGridKey,
    maxVisibleEntries,
    ...rest
  } = props;
  // const localize = useLocalize();

  const [data, setData] = React.useState<any[]>(() => (Array.isArray(rows) ? rows : []));
  const [filterModelState, setFilterModelState] = React.useState<DataGridFilterModel | undefined>(filterModel);
  // const [visibilityModelState, setVisibilityModelState] = React.useState<DataGridColumnVisibilityModel | undefined>(
  //   columnVisibilityModel
  // );

  // This effect should only be fired, in case of a lazy initialized filter
  // induced by the Query-Promise (Queuing-Feature)
  // const filterInit = filterModelState ? undefined : filterModel;
  // React.useEffect(() => {
  //   console.log("FilterModel changed (init)");
  //   filterInit && setFilterModelState(filterInit);
  // }, [filterInit]);
  React.useEffect(() => {
    setFilterModelState(state => {
      if (state !== filterModel) {
        return filterModel;
      }
      return state;
    });
  }, [filterModel]);

  const gridApi = useGridApiRef();

  React.useEffect(() => {
    const data = Array.isArray(rows) ? rows : [];
    // always reset data on row change
    setData(data);
    if (api) {
      // setData(data);
      // TODO: QuickSearchCache löschen
      // const cache = data.length > 0 ? new QuickSearchCache(props.columns, gridApi) : undefined;
      const setFilterModelStateDebounced = debounce(setFilterModelState, 200);
      api.current = {
        quickfilter: (filter: string) => {
          setFilterModelStateDebounced(state => ({ ...state, items: state?.items ?? [], quickFilterValues: [filter] }));
          onQuickFilterChange?.(filter);
          // console.log("export", gridApi.current.exportState());
          // cache && setData(cache.filterRows(data, filter));
        },
        startEdit: (id: GridRowId, field: string) => {
          gridApi.current.startCellEditMode({ id, field });
        },
        stopEdit: (id: GridRowId, field: string) => {
          if (gridApi.current.getCellMode(id, field) === "edit") {
            gridApi.current.stopCellEditMode({ id, field });
          }

          // mib: mui uprade to v6: seems obsolete
          // gridApi.current.setCellMode(id, field, "view");
        },
        exportState: () => gridApi.current.exportState(),
        restoreState: state => gridApi.current.restoreState(state as never)
      };
    }

    // columns should not be changeable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, rows]);

  const { component: gridViewComponent } = useDataGridViews({
    dataGridKey,
    dataGridApi: gridApi,
    loading: rest.loading
  });

  // locale
  const [localeText, setLocaleText] = React.useState<Partial<GridLocaleText>>();
  const localizationService = useLocalizationService();
  React.useEffect(() => {
    // TODO: Lazyload and more langs
    if (localizationService.activeLang === "de") {
      setLocaleText(deDE.components.MuiDataGrid.defaultProps.localeText);
    } else {
      setLocaleText(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const [localeText] = React.useState(() => {
  //   return Object.keys(dataGridTextResources).reduce((p, c) => {
  //     const val = dataGridTextResources[c as keyof typeof dataGridTextResources];
  //     if (val) {
  //       p[c] = localize(val);
  //     } else if (val) {
  //       p[c] = localize(val);
  //     }
  //     return p;
  //   }, {} as any);
  // });

  const [sortModelState, setSortModelState] = React.useState<GridSortModel | undefined>(sortModel);

  const handleFilterModelChange = (model: GridFilterModel, details: GridCallbackDetails<"filter">) => {
    setFilterModelState(model);
    if (details.reason) {
      // we dont do anything without reason ;-)
      // relevant changes always have areason
      onFilterModelChange && onFilterModelChange(model);
      onQuickFilterChange && onQuickFilterChange(model.quickFilterValues?.join() ?? "");
    }
  };
  // const handleVisibilityModelChange = (model: GridColumnVisibilityModel) => {
  //   setVisibilityModelState(model);
  // };

  // React.useEffect(() => {
  //   setFilterModelState(filterModel);
  // }, [filterModel]);

  const height = calculateDataGridHeight(data, maxVisibleEntries);
  const { classes } = useStyles({ height });

  return (
    <DataGridPro
      // localeText
      initialState={{ columns: { columnVisibilityModel } }}
      className={classes.xGrid}
      slots={{
        toolbar: CustomToolbar,
        footer: props.customFooterComponents
          ? (slotProps: GridSlotsComponentsProps["footer"]) =>
              CustomFooter(props.customFooterComponents, slotProps, gridApi)
          : undefined
      }}
      slotProps={{
        toolbar: { ...props, gridViewComponent, filterModelState },
        filterPanel: {
          // Force usage of "And" operator
          logicOperators: [GridLogicOperator.And],
          // Display columns by ascending alphabetical order
          columnsSort: "asc",
          filterFormProps: {
            // Customize inputs by passing props
            logicOperatorInputProps: {
              variant: "outlined",
              size: "small"
            },
            columnInputProps: {
              variant: "outlined",
              size: "small",
              sx: { mt: "auto" }
            },
            operatorInputProps: {
              variant: "outlined",
              size: "small",
              sx: { mt: "auto" }
            },
            valueInputProps: {
              InputComponentProps: {
                variant: "outlined",
                size: "small"
              }
            }
          },
          sx: {
            // Customize inputs using css selectors
            "& .MuiDataGrid-filterForm": { p: 2 },
            "& .MuiDataGrid-filterForm:nth-of-type(even)": {
              backgroundColor: (theme: Theme) => (theme.palette.mode === "dark" ? "#444" : "#f5f5f5")
            },
            "& .MuiDataGrid-filterFormLogicOperatorInput": { mr: 2 },
            "& .MuiDataGrid-filterFormColumnInput": { mr: 2, width: 150 },
            "& .MuiDataGrid-filterFormOperatorInput": { mr: 2 },
            "& .MuiDataGrid-filterFormValueInput": { width: 200 }
          }
        }
      }}
      getRowId={row => (row.id ? row.id : row._id)}
      {...rest}
      rows={data}
      apiRef={gridApi}
      localeText={localeText}
      disableMultipleRowSelection
      sortModel={sortModelState}
      onSortModelChange={params => {
        setSortModelState(params);
      }}
      filterModel={filterModelState}
      onFilterModelChange={handleFilterModelChange}
      columns={columns}
      // columnVisibilityModel={visibilityModelState}
      // onColumnVisibilityModelChange={handleVisibilityModelChange}
      hideFooterSelectedRowCount
      treeData={!!rest.getTreeDataPath}
    />
  );
}

// show {maxEntries}
function calculateDataGridHeight(contentArray?: DataGridRowsProp, maxVisibleEntries?: boolean | number) {
  if (maxVisibleEntries === undefined) {
    return "100%";
  } else {
    let numberOfRows = 1;
    if (contentArray && contentArray.length > 0) {
      const maxEntries = Number.isInteger(maxVisibleEntries) ? (maxVisibleEntries as number) : 5;
      numberOfRows = contentArray.length > maxEntries ? maxEntries : contentArray.length < 1 ? 1 : contentArray.length;
    }
    return `${numberOfRows * 50 + 125}px`;
  }
}

function renderHoverCopy(value: any, inlineAlignLeft?: boolean) {
  return (
    <CopyToClipoardHoverButton inline={inlineAlignLeft ? "leftAlign" : "rightAlign"} copyText={value}>
      {value}
    </CopyToClipoardHoverButton>
  );
}

interface CachedColumn {
  col: DataGridColumnDef;
  name: string;
  getValue: (row: any, col: DataGridColumnDef) => any;
}

class QuickSearchCache {
  private _columns: CachedColumn[];

  constructor(columns: DataGridColumnDef[], gridApi: React.MutableRefObject<GridApi>) {
    const rowValue = (row: any, col: DataGridColumnDef) => row[col.field] as never;
    const getterValue = (row: any, col: DataGridColumnDef) => col.valueGetter!(rowValue(row, col), row, col, gridApi);
    const formattedValue = (row: any, col: DataGridColumnDef) =>
      col.valueFormatter!(rowValue(row, col), row, col, gridApi);

    this._columns = columns.reduce((colList, col) => {
      if (col.filterable || col.filterable === undefined) {
        colList.push({
          col,
          name: col.field,
          getValue: col.valueFormatter ? formattedValue : col.valueGetter ? getterValue : rowValue
        });
      }

      return colList;
    }, [] as CachedColumn[]);
  }

  escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

  filterRows(rows: any[], filter: string | undefined) {
    if (filter) {
      const start = Date.now();
      const searchRegex = new RegExp(this.escapeRegExp(filter), "i");

      const result = rows.filter((row: any) => this.filter(searchRegex, row));
      console.log("quicksearch", Date.now() - start, "ms", "columns:", this._columns.length);
      return result;
    } else {
      return rows;
    }
  }

  filter(search: RegExp, row: any) {
    return this._columns.some(col => {
      const value = col.getValue(row, col.col);

      if (value && typeof value !== "string" && typeof value !== "number") {
        // remove column
        this._columns = this._columns.filter(c => c !== col);
        return false;
      } else if (value) {
        return search.test(value.toString());
      } else {
        return false;
      }
    });
  }

  // filter2(search: RegExp, row: any) {
  //   return Object.keys(row).some(field => {
  //     const col = this._columns.find(col => col.name === field);
  //     if (col) {
  //       const value = col.getValue(row, col.col);

  //       // console.log("quicksearch-val", value);
  //       const include = value && (typeof value === "string" || typeof value === "number");
  //       if (include) {
  //         return search.test(value.toString());
  //       } else {
  //         return false;
  //       }
  //     }
  //   });
  // }
}

function CustomToolbar(
  props: GridSlotProps["toolbar"] &
    Pick<DataGridDefinition, "showExportButton" | "customToolbarComponents"> & {
      showFilterButton?: boolean;
      gridViewComponent?: React.ReactNode;
    }
) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  let components: React.ReactNode[] = [];
  if (props.showExportButton && !sm) {
    components.push(<GridToolbarExport key="export" />);
  }
  if (props.showFilterButton && !sm) {
    components.push(<GridToolbarFilterButton key="filter" />);
  }
  if (props.customToolbarComponents) {
    components.push(props.customToolbarComponents);
  }
  if (props.gridViewComponent) {
    components.push(props.gridViewComponent);
  }
  return <GridToolbarContainer sx={{ pb: 0.5 }}>{components}</GridToolbarContainer>;
}

function CustomFooter(
  component: React.ReactNode,
  slotProps: GridSlotsComponentsProps["footer"],
  gridApi: React.MutableRefObject<GridApi>
) {
  let components: React.ReactNode[] = [component];
  components.push(<TotalRowsFooterComponent key="totalRows" slotProps={slotProps} gridApi={gridApi} />);
  // if (opts.showExportButton) {
  //   components.push(<GridToolbarExport key="export" />);
  // }
  // if (showFilterButton) {
  //   components.push(<GridToolbarFilterButton key="filter" />);
  // }
  // if (opts.customToolbarComponents) {
  //   components.push(opts.customToolbarComponents);
  // }
  return <GridFooterContainer>{components}</GridFooterContainer>;
}

export function TotalRowsFooterComponent({
  gridApi,
  slotProps
}: {
  gridApi: React.MutableRefObject<GridApi>;
  slotProps: GridSlotsComponentsProps["footer"];
}) {
  const total = gridApi.current.getRowsCount();
  const visibleTopLevelRowCount = useGridSelector(gridApi, gridFilteredTopLevelRowCountSelector);

  return (
    <Box sx={{ marginX: 2 }}>
      <TextResource resKey={dataGridTextResources.footerTotalLabel} />{" "}
      {total === visibleTopLevelRowCount ? (
        total
      ) : (
        <>
          <span>{visibleTopLevelRowCount}</span> <TextResource resKey={dataGridTextResources.footerTotalPartText} />{" "}
          <span>{total}</span>
        </>
      )}
    </Box>
  );
}

export function GridIconItem(props: { icon: React.ReactElement; label?: string }) {
  return (
    <Box display="flex" alignItems="center">
      <Avatar sx={{ marginRight: 1 }}> {props.icon}</Avatar>
      {props.label}
    </Box>
  );
}
