import {
  buildRcPromise,
  ClientException,
  DialogButtonType,
  DialogButtonTypeTextResources,
  ErrorType,
  ILocalizationService,
  INotificationService,
  isErrorType,
  IServicesAccessor,
  isResourceKeyReference,
  LocalizationServiceId,
  localizeEnum,
  Localizer,
  NotificationService,
  ResourceKey,
  shouldRetry,
  useServices
} from "@emibee/lib-app-common";
import * as log from "loglevel";
import React from "react";

// var originalFactory = log.methodFactory;
// (log as any).methodFactory = function (methodName: string, logLevel: any, loggerName: string) {
//     var rawMethod = originalFactory(methodName, logLevel, loggerName);

//     return function (message: string) {
//         rawMethod("Newsflash: " + message);
//     };
// };
log.setLevel("trace");

export interface ProgressHandle {
  update?: (progress: number | undefined) => void;
}
export interface AsyncErrorNotifier {
  (error: Error | string | ErrorType): void;
}
export interface IErrorBoundary {
  asyncError: AsyncErrorNotifier;
  triggerReload: () => void;
}

export interface BlockScreenInfo {
  open: boolean;
  query?: boolean;
  progress?: number;
  message?: any;
  title?: string;
  buttons?: ButtonInfo[];
}
export interface QueryInfo {
  title: string;
  message: any;
  buttons: ButtonInfo[];
  cancelNotification: string;
}

interface NotificationHandle {
  close: () => void;
  progress: (val: number) => void;
  queryRetry: () => Promise<boolean>;
}

export interface ButtonInfo {
  title: string | React.ReactNode;
  value: number;
  default?: boolean;
}

function buildButtonInfo(
  buttons: DialogButtonType[],
  defaultButton: DialogButtonType | undefined,
  localize: Localizer
): ButtonInfo[] {
  return buttons.map((btn, idx) => ({
    title: localizeEnum(DialogButtonType, DialogButtonTypeTextResources, btn, localize),
    value: btn,
    default: defaultButton !== undefined ? btn === defaultButton : idx === buttons.length - 1
  }));
}

export interface BlockScreenHandle {
  close: () => void;
  setPromise: (promise: Promise<any>) => void;
  progress: (val: number, title: string, message: any) => void;
  query: (info: QueryInfo) => Promise<number>;
}

export interface CustomDialogProps<AcceptT = void> {
  onClose: () => void | Promise<void>;
  onAccept?: (data: AcceptT) => void;
}
export interface INotificationServiceMH extends INotificationService {
  errorCheckDecision: (
    error: Error,
    retryCount?: number,
    prevCount?: number,
    notifyIfNot?: boolean,
    boundaryRefresh?: () => void,
    blockScreen?: BlockScreenHandle
  ) => Promise<NotificationHandle | boolean | undefined>;
  blockScreen: (waitDurationMs: number) => BlockScreenHandle;
  shouldRetry: (error: Error) => boolean;
  customDialog: <PropsT extends CustomDialogProps<AcceptT>, AcceptT = void>(
    dialog: React.FunctionComponent<PropsT>,
    props: Omit<PropsT, "onClose" | "onAccept"> & Partial<CustomDialogProps<AcceptT>>,
    dialogId?: string | number
  ) => Promise<AcceptT | undefined>;
}

export enum NotificationKind {
  default,
  info,
  warning,
  success,
  error
}
//export enum DialogStyle { AbortContinue }
export enum NotificationStyle {
  Notification,
  Dialog,
  BlockScreen,
  Custom
}
interface NotificationProviderArgs {
  style?: NotificationStyle;
  message?: string | React.ReactNode;
  title?: string | React.ReactNode;
  kind?: NotificationKind;
  persist?: boolean;
  progress?: number;
  progressHandle?: ProgressHandle;
  closeHandle?: any;
  customElement?: React.ReactElement;
  customOnCloseHandler?: () => void;
  dialogId?: string | number;
  buttons?: ButtonInfo[];
}

export class NotificationDispatcher implements INotificationServiceMH {
  public get id() {
    return NotificationService.id;
  }
  private _services!: IServicesAccessor;
  private _localizationService?: ILocalizationService;
  set services(value: IServicesAccessor) {
    this._services = value;
  }
  get localizationService() {
    if (!this._localizationService) {
      this._localizationService = this._services.get(LocalizationServiceId);
    }
    return this._localizationService;
  }
  private _handler: ((args: NotificationProviderArgs) => any) | undefined;
  public registerNotificationProvider(handler: (args: NotificationProviderArgs) => any) {
    if (this._handler) {
      logger.error("NotificationService", "NotificationProvider already registered.");
    }
    this._handler = handler;
  }

  private _pubNotification(message: string | React.ReactNode, kind: NotificationKind, args?: NotificationProviderArgs) {
    if (!this._handler) {
      logger.warn("NotificationService", "No NotificationProvider registered:", kind, message);
    } else {
      args = args || {};
      return this._handler({ ...args, message, kind });
    }
  }

  private _closeNotification(closeHandle: any) {
    this._handler && this._handler({ closeHandle });
  }
  private _dialogNotification(
    title: string | React.ReactNode,
    message: string | React.ReactNode,
    buttons: ButtonInfo[]
  ): Promise<number> {
    if (this._handler)
      return this._handler({
        title,
        message,
        buttons,
        style: NotificationStyle.Dialog
      });
    else return Promise.resolve(0);
  }
  private _blockScreenNotification(): undefined | ((info: BlockScreenInfo) => Promise<number> | undefined) {
    if (this._handler) return this._handler({ style: NotificationStyle.BlockScreen });
  }

  private _buildButtons(...buttons: DialogButtonType[]) {
    return buildButtonInfo(buttons, undefined, this.localizationService.localize.bind(this.localizationService));
  }
  public error(error: Error | string | ErrorType | unknown) {
    const message =
      typeof error === "string"
        ? error
        : isErrorType(error) && error.resKey
        ? error.resKey
        : error instanceof Error
        ? error.message
        : error;
    logger.infoScope("notify", "NotificationService", "error", message);

    if (typeof message === "string" || isResourceKeyReference(message)) {
      this._pubNotification(this.localizationService.localize(message) as string, NotificationKind.error);
    }
  }

  public async errorCheckDecision(
    error: Error,
    retryCount = 1,
    prevCount = 0,
    notifyIfNot = true,
    boundaryRefresh?: () => void,
    blockScreen?: BlockScreenHandle
  ): Promise<NotificationHandle | boolean | undefined> {
    let queryInfo: QueryInfo | undefined;

    if (this.shouldRetry(error)) {
      const dlgMessage = `After ${prevCount + retryCount} attempts the problem "${
        error.message
      }" has not been resolved. Do you still want to continue retrying?`;
      logger.infoScope("notify", "NotificationService", "error", error.message);
      if (blockScreen) {
        queryInfo = {
          buttons: this._buildButtons(DialogButtonType.cancel, DialogButtonType.continue),
          title: "Retry failed",
          message: dlgMessage
        } as QueryInfo;
        return {
          close: () => blockScreen.close(),
          progress: val => blockScreen.progress(val, "Retrying operation due to below error", error.message),
          queryRetry: () => blockScreen.query(queryInfo!).then(r => r === 1)
        };
      } else {
        const progressHandle: ProgressHandle = {};
        const handle = this._pubNotification(error.message, NotificationKind.warning, {
          persist: true,
          progress: retryCount,
          progressHandle
        });
        if (handle) {
          return {
            close: () => this._closeNotification(handle),
            progress: val => progressHandle.update && progressHandle.update(val),
            queryRetry: () =>
              this._dialogNotification(
                "Continue retrying?",
                dlgMessage,
                this._buildButtons(DialogButtonType.cancel, DialogButtonType.continue)
              ).then(r => r === 1)
          };
        }
      }
    } else if ((queryInfo = this.shouldQuery(error)) !== undefined) {
      logger.infoScope("notify", "NotificationService", "error", error.message);
      let result;
      if (blockScreen) {
        result = await blockScreen.query(queryInfo);
      } else {
        result = await this._dialogNotification(queryInfo.title, queryInfo.message, queryInfo.buttons);
      }

      if (result && boundaryRefresh) {
        boundaryRefresh();
      } else if (result === 0) {
        this.info(queryInfo.cancelNotification);
      }
      return result === 1;
    } else if (notifyIfNot) {
      this.error(error);
    }
  }
  public reportIssue(error: Error | string | ErrorType, silent?: boolean) {
    logger.error("NotificationService", "reportIssue", error);
    !silent && this._pubNotification("The problem has been reported.", NotificationKind.info);
  }
  public success(message: string | ResourceKey) {
    logger.infoScope("notify", "NotificationService", "success", message);
    this._pubNotification(this.localizationService.localize(message), NotificationKind.success);
  }
  public info(message: string | ResourceKey) {
    logger.infoScope("notify", "NotificationService", "info", message);
    this._pubNotification(this.localizationService.localize(message), NotificationKind.info);
  }

  public dialog(
    title: ResourceKey | string,
    message: ResourceKey | string,
    buttons: DialogButtonType[],
    defaultButton?: DialogButtonType
  ): Promise<DialogButtonType> {
    return this._dialogNotification(
      this.localizationService.localize(title),
      this.localizationService.localize(message),
      buildButtonInfo(buttons, defaultButton, this.localizationService.localize.bind(this.localizationService))
    );
  }

  public blockScreen(waitDurationMs: number): BlockScreenHandle {
    let notHandle: undefined | ((info: BlockScreenInfo) => Promise<number> | undefined);

    const timeout = setTimeout(() => {
      notHandle = this._blockScreenNotification();
    }, waitDurationMs);
    //const handle = this._blockScreenNotification();
    // handle && promise && promise.finally(() => {
    //     handle({open: false });
    // })
    const clear = () => {
      clearTimeout(timeout);
      notHandle && notHandle({ open: false });
    };
    return {
      setPromise: p =>
        p.finally(() => {
          clear();
        }),
      close: () => clear(),
      progress: (val, title, message) => notHandle && notHandle({ open: true, progress: val, title, message }),
      query: (info: QueryInfo) => {
        if (!notHandle) {
          clearTimeout(timeout);
          notHandle = this._blockScreenNotification();
        }
        if (notHandle) {
          return notHandle({
            open: true,
            query: true,
            title: info.title,
            message: info.message,
            buttons: info.buttons
          }) as Promise<number>;
        } else return Promise.resolve(0);
      }
    };
  }

  customDialog<PropsT extends CustomDialogProps<AcceptT>, AcceptT = void>(
    dialog: React.FunctionComponent<PropsT>,
    props: Omit<PropsT, "onClose" | "onAccept"> & Partial<CustomDialogProps<AcceptT>>,
    dialogId?: string | number
  ) {
    if (this._handler) {
      const rcPromise = buildRcPromise<AcceptT | undefined>();
      const hide = () => {
        this._handler!({
          style: NotificationStyle.Custom, // will remove it
          dialogId
        });
      };
      this._handler({
        style: NotificationStyle.Custom,
        dialogId,
        customElement: React.createElement(dialog, {
          ...props,
          onClose: async () => {
            hide();
            props.onClose && (await props.onClose());
            rcPromise.resolve(undefined);
          },
          onAccept: data => {
            hide();
            rcPromise.resolve(data);
          }
        } as PropsT)
      });
      return rcPromise.promise;
    } else throw new Error("Cannot show customDialog: No NotificationProvider registered!");
  }

  public shouldRetry(error: Error) {
    return shouldRetry(error);
  }

  public shouldQuery(error: Error) {
    if (error instanceof ClientException) {
      if (error.code === 409) {
        return {
          buttons: this._buildButtons(DialogButtonType.reload, DialogButtonType.cancel),
          title: "Concurrent Server update",
          message: [
            "Your operation cannot be processed on the server, because your change has not been made on the latest data.",
            "You can either cancel your operation or reload the changed data and loose your changes ?"
          ],
          cancelNotification: "Operation has been canceled."
        } as QueryInfo;
      }
    }
  }
}

export function useNotificationServiceMH() {
  return useServices().get(NotificationService) as INotificationServiceMH;
}
