import { buildTextResourcesMH, Domains } from "../../core/textResourceScopes";

export const textResources = buildTextResourcesMH({
  scope: "Views",
  namespace: Domains.car,
  resources: {
    // car List
    carListPageTitle: "Car List",
    carListRouteListText: "Car List",
    carListRouteDetailsText: "Car List",
    createCarTitle: "Create Car",

    // car Details
    detailsPageTitle: "Car Details",
    sectionInfo: "Info",
    sectionSpecifications: "Specifications",
    sectionDetails: "Details",
    sectionTabFiles: "Images",
    sectionTabCheckAndCondition: "Check & Condition",
    sectionQuickCheck: "Quick Check",
    sectionProfessionalCarCheck: "Professional Car Check",
    sectionImages: "Images",
    sectionEquipment: "Equipment",
    sectionCondition: "Condition",
    sectionActions: "Actions",
    sectionAuctions: "Auctions",
    sectionShare: "Share Car Details",

    vin: "VIN",
    equipment: "Equipment",
    hp: "HP",
    displacement: "Displacement",
    maker: "Maker",
    model: "Model",
    variant: "Variant",
    previousOwners: "Previous Owners",
    temp_gear: "Gear",
    transmission: "Transmission",
    vmax: "VMax",
    color: "Color",
    doors: "Doors",
    temp_fuel: "Fuel Type",
    fuelType: "Fuel Type",
    vintage: "Vintage",
    initialRegistration: "Initial Registration",
    mileage: "Mileage",
    condition: "Condition",
    cylinder: "Cylinder",
    buttonClose: "Close",
    buttonStartAuction: "Start Auction",
    buttonEditCar: "Edit Car",
    titleCondition: "Condition",
    titleEquipment: "Equipment",
    vatDeductible: "VAT deductible",
    keys: "Keys",
    serviceHistory: "Service History",
    purchasePrice: "Purchase Price",
    inspection: "Inspection",

    titlePublishCar: "Publish Car",
    buttonPublishCar: "Publish Car",
    infoTextPublishCar: "If you publish this vehicle, it is visible for everyone. Do you want to publish this vehicle?",

    buttonDeleteCar: "Delete Car",
    share: "Share",
    actions: "Actions",
    shareViaEmail: "Share via eMail",
    shareViaWhatsApp: "Share via WhatsApp",
    startAuction: "Start Auction",
    titleDeleteCar: "Permanently Remove Vehicle",
    infoTextDeleteCar:
      "Are you sure you want to delete this vehicle? This action is irreversible. Please proceed only if you are certain you want to permanently remove this vehicle.",
    shareEmailLinkText:
      "Schauen Sie sich dieses Auto bei MotorHammer an: {{title}} {{variant}} - Fin: {{vin}} \n\nHier der Link: {{url}}",
    shareWhatsAppLinkText:
      "Schauen Sie sich dieses Auto bei MotorHammer an: {{title}} {{variant}} - Fin: {{vin}} \n\nHier der Link: {{url}}",

    actionDeleteCarTitle: "Delete Car",
    actionDeleteCar: "Delete Car",
    actionDeleteCarDescription: "Here you can Delete the car",
    actionDeleteCarSuccessToast: "Car was deleted sucessfully.",
    actionEditCarTitle: "Edit Car",
    actionEditCar: "Edit Car",
    actionEditCarDescription: "Here you can edit the car",
    actionStartAuctionTitle: "Start Auction",
    actionStartAuction: "Start Auction",
    actionStartAuctionDescription: "Start an Auction",
    actionShareViaEmail: "Share via eMail",
    actionShareViaEmailTitle: "Share via eMail",
    actionShareViaEmailDescription: "Share via eMail",
    actionShareViaWhatsApp: "Share via WhatsApp",
    actionShareViaWhatsAppTitle: "Share via WhatsApp",
    actionShareViaWhatsAppDescription: "Share via WhatsApp",
    actionShareViaMHApp: "Share via Motorhammer",
    actionShareViaMHAppTitle: "Share via Motorhammer",
    actionShareViaMHAppDescription: "Share via Motorhammer",

    // car Preview

    titleDataBlock: "Technical Data",
    titleInfoBlock: "{{maker}} {{model}} {{variant}}",
    subTitleInfoBlock: "{{vin}}",
    errorDeletingCar: "Error deleting car."
  }
});
