import {
  buildSelectOptions,
  buildTextResources4Data,
  buildTextResources4Enum,
  dataKitBuilder,
  enumNamespace
} from "@emibee/lib-app-common";
import {
  AuctionSoldState,
  AuctionType,
  AuctionsInWindowArgs,
  GetAuctionListArgs,
  GetAuctionsByVinArgs,
  IAuctionGraph,
  IDutchStep,
  PostalAddress,
  SearchAuctionsArgs,
  LegacyAuctionRenegotiation
} from "@mh/common";

import { DateRangeFetchMoreArgs } from "@emibee/lib-shared";

import { GetActiveAuctionByCarIdArgs } from "@mh/common";
import { ImageCell } from "../../controls/ImageCell";
import { Domains, dataNamespace } from "../../core/textResourceScopes";
import { getAuctionSoldState } from "../../tools/AuctionTools";
import { sortFn } from "../../tools/utils";
import { PostalAddressDataKit } from "../organization/postalAddress";
// export interface AuctionListItem extends AuctionMin {
//   dealerId?: string;
//   dealerName?: string;
//   hasAuctionEnded: boolean;
//   startBid: number;
//   highestBid: number;
//   _highestBidder?: UserDetails;
//   auctionEndTime: number;
//   images?: CarImage[];
//   mainImage?: CarImage;
// reservePrice?: number;
// targetPrice?: number;
// isProspectDeal?: boolean;
// }

export const auctionSoldStateTextResources = buildTextResources4Enum<typeof AuctionSoldState>({
  scope: "Data",
  namespace: enumNamespace(Domains.auction, "AuctionSoldState"),
  resources: {
    open: "open",
    sold: "sold",
    notSold: "not sold",
    running: "running"
  }
});

export const auctionTypeTextResources = buildTextResources4Enum<typeof AuctionType>({
  scope: "Data",
  namespace: enumNamespace(Domains.auction, "AuctionType"),
  resources: {
    bundle: "Bundle",
    single: "Auction",
    prospect: "Prospect",
    dutch: "Dutch",
    spotbuy: "Spot Buy"
  }
});
// export const prospectStateTextResources = buildTextResources4Enum<typeof ProspectState>({
//   scope: "Data",
//   namespace: enumNamespace(Domains.auction, "AuctionType"),
//   resources: {
//     open: "open",
//     sold: "sold",
//     notSold: "notSold"
//   }
// });

const dutchStepTextResources = buildTextResources4Data<IDutchStep>({
  scope: "Data",
  namespace: dataNamespace(Domains.auction, "DutchSteps"),
  resources: {
    stepStartTime: "Step Start Time",
    stepEndTime: "Step End Time",
    amount: "Amount"
  }
});
export const DutchStepDataKit = dataKitBuilder(dutchStepTextResources).build();

const renegotiationTextResources = buildTextResources4Data<LegacyAuctionRenegotiation>({
  scope: "Data",
  namespace: dataNamespace(Domains.auction, "Renegotiation"),
  resources: {
    price: "Price",
    _offeredBy: "Offered By",
    createdAt: "created at"
  }
});
export const RenegotiationDataKit = dataKitBuilder(renegotiationTextResources).build();

export type IAuctionListItemUI = Pick<
  IAuctionGraph,
  | "id"
  | "auctionType"
  | "auctionEndTime"
  | "targetPrice"
  | "reservePrice"
  | "isProspectDeal"
  | "mainImage"
  | "ownerOrgName"
  | "lastBid"
  | "bidderInfo"
  | "statistics"
  | "car"
  | "carBundle"
  | "createdAt"
  | "keyAccountName"
  // | "steps"
  | "startPrice"
  | "stopPrice"
  | "stepAmount"
  | "auctionDuration"
  | "alternativePriceAccepted"
  | "state"
  | "address"
  | "myFavorite"
  | "renegotiation"
> & {
  readonly vCarInfo?: AuctionSoldState;
  readonly vBidInfo?: number;
  readonly vSearchString?: string;
  readonly vVin?: string;
  readonly vMaker?: string;
  readonly vModel?: string;
  readonly vLastBid?: number;
  readonly vLastBidderName?: string;
  readonly vVisitorCount?: number;
  readonly vStarredCount?: number;
  readonly auctionState?: AuctionSoldState;
  readonly auctionStartTime?: Date;
  readonly vCity?: string;
  readonly vZip?: string;
  readonly vCountry?: string;
  readonly vRenegotiation?: number;
  readonly allowExport?: boolean;
  readonly distance?: number;
};

const auctionListItemTextResources = buildTextResources4Data<IAuctionListItemUI>({
  scope: "Data",
  namespace: "Auction",
  resources: {
    // dealerName: "DealerName",
    // dealerId: "Dealer",
    id: "ID",
    vCarInfo: "CarInfo",
    vBidInfo: "BidInfo",
    vSearchString: "Search String",
    vVin: "Vin",
    vMaker: "Maker",
    vModel: "Model",
    auctionType: "Type",
    auctionEndTime: "Endtime",
    startPrice: "Start Price",
    stopPrice: "Stop Price",

    vLastBid: "Highest bid",
    vLastBidderName: "Highest Bidder",
    vStarredCount: "StarredBy",
    vVisitorCount: "Visitors",
    statistics: "Statistics",
    mainImage: "Main Image",
    // hasAuctionEnded: "auctionEnded",
    // _highestBidder: "Highest Bidder",
    // images: "Images",
    // mainImage: "Main Image",
    reservePrice: "Reserve Price",
    targetPrice: "Target Price",
    isProspectDeal: "Deal/noDeal",
    ownerOrgName: "Organization",
    car: "Car",
    carBundle: "CarBundle",
    lastBid: "Last bid",
    bidderInfo: "Bidder Info",
    address: "Address",
    vCity: "City",
    vCountry: "Country",
    vZip: "Zip",
    createdAt: "Created At",
    keyAccountName: "Key Account",
    auctionStartTime: "Started At",
    // steps: "Steps",
    stepAmount: "Step amount",
    auctionDuration: "Auction duration",
    auctionState: "Auction State",
    alternativePriceAccepted: "Alternative price accepted",
    state: "Auction State",
    myFavorite: "My Favorite",
    allowExport: "Allow Export",
    distance: "Distance",
    renegotiation: "Renegotiation",
    vRenegotiation: "Renegotiation Info"
  }
});

export const AuctionListItemDataKit = dataKitBuilder(auctionListItemTextResources)
  .fieldType("dateTime", "auctionEndTime", "createdAt", "auctionStartTime")
  .field("auctionType")
  .props({
    options: buildSelectOptions(AuctionType, auctionTypeTextResources)
  })
  .field("vCarInfo")
  .props({
    // to be filterable
    options: buildSelectOptions(AuctionSoldState, auctionSoldStateTextResources),
    valueGetter(record) {
      return getAuctionSoldState(record);
    }
  })
  .field("vBidInfo")
  .props({
    valueGetter(record) {
      return record.lastBid?.amount;
    }
  })
  .virtualField("vVin")
  .props({
    valueGetter(record) {
      return record.car?.vin;
    }
  })
  .virtualField("vCity")
  .props({
    valueGetter(record) {
      return record.address?.city;
    }
  })
  .virtualField("vZip")
  .props({
    valueGetter(record) {
      return record.address?.zip;
    }
  })
  .virtualField("vCountry")
  .props({
    valueGetter(record) {
      return record.address?.country;
    }
  })
  .virtualField("vMaker")
  .props({
    valueGetter(record) {
      return record.car?.maker;
    }
  })
  .virtualField("vModel")
  .props({
    valueGetter(record) {
      return record.car?.model;
    }
  })
  .virtualField("vLastBid")
  .props({
    valueGetter(record) {
      return record.lastBid?.amount ?? null;
    }
  })
  .virtualField("vLastBidderName")
  .props({
    valueGetter(record) {
      return record.bidderInfo?.bidderDisplayName ?? undefined;
    }
  })
  .virtualField("auctionState")
  .props({
    options: buildSelectOptions(AuctionSoldState, auctionSoldStateTextResources),
    valueGetter(record) {
      return getAuctionSoldState(record);
    }
  })
  .virtualField("vVisitorCount")
  .props({
    valueGetter(record) {
      return record.statistics?.visitorCount;
    }
  })
  .virtualField("vStarredCount")
  .props({
    valueGetter(record) {
      return record.statistics?.starredCount;
    }
  })
  .virtualField("vRenegotiation")
  .props({
    valueGetter(record) {
      return record.renegotiation?.createdAt ?? null;
    }
  })
  .field("mainImage")
  .props({
    valueFormatter(mainImage) {
      // console.log("🚀 ~ valueFormatter ~ mainImage:", mainImage);
      return ImageCell({ image: mainImage, width: 80, height: 60 });
    }
  })
  .field("address")
  .props({ complex: PostalAddressDataKit })
  .field("renegotiation")
  .props({ complex: RenegotiationDataKit })
  // .field("_highestBidder")
  // .props({ complex: UserMinDataKit })
  // .field("images")
  // .props({ complex: MediaFileDataKit })
  // .field("mediaFiles")
  // .props({ complex: MediaFileDataKit })
  .queries(qb => ({
    getAuctionList: qb
      .query("getAuctionList", { includeCV: true, typeName: "Auction" })
      .args<DateRangeFetchMoreArgs<GetAuctionListArgs>>("GetAuctionListArgs", true)
      .incrementalFetch("DateRange")
      .ListResult.selectFields(
        "auctionEndTime",
        "auctionType",
        // "dealerId",
        // "dealerName",
        // "hasAuctionEnded",
        "mainImage",
        "lastBid",
        "bidderInfo",
        "isProspectDeal",
        "id",
        "reservePrice",

        // "startBid",
        "startPrice",
        "stopPrice",
        "auctionDuration",
        "stepAmount",

        "targetPrice",
        "ownerOrgName",
        "createdAt",
        "keyAccountName",
        "alternativePriceAccepted",
        "myFavorite",
        "renegotiation",
        "allowExport",
        "distance"
      )
      // .subBuilder("_highestBidder", builder => {
      //   builder.selectFields("vDisplayName");
      // })
      .subFields("statistics", "visitorCount", "starredCount")
      .subFields("mainImage", "uri", "mediaType", "v2")
      .subFields("car", "vin", "maker", "model", "initialRegistration", "mileage", "fuelType")
      .subFields("carBundle", "mediaFiles", "vin", "maker", "model", "initialRegistration", "mileage", "fuelType")
      // .subFields("steps", "amount", "stepStartTime", "stepEndTime")
      .subFields("bidderInfo", "bidderId", "bidderDisplayName")
      .subBuilder("address", builder =>
        builder.customType<Partial<PostalAddress>>().selectFields("zip", "city", "countryCode", "country")
      )
      .subBuilder("renegotiation", builder =>
        builder.customType<Partial<LegacyAuctionRenegotiation>>().selectFields("_offeredBy", "createdAt", "price")
      )
      .subFields("lastBid", "id", "amount", "count", "createdAt")
      .build(),
    searchAuction: qb
      .query("searchAuctions", { includeCV: true })
      .args<SearchAuctionsArgs>("SearchAuctionsArgs", true)
      // .ListResult.allFields(true)
      .ListResult.selectFields(
        "auctionEndTime",
        "auctionType",
        // "dealerId",
        // "dealerName",
        // "hasAuctionEnded",
        "mainImage",
        "lastBid",
        "bidderInfo",
        "isProspectDeal",
        "id",
        "reservePrice",

        // "startBid",
        "startPrice",
        "stopPrice",
        "auctionDuration",
        "stepAmount",
        "address",
        "targetPrice",
        "ownerOrgName",
        "createdAt",
        "keyAccountName"
      )
      // .subBuilder("_highestBidder", builder => {
      //   builder.selectFields("vDisplayName");
      // })
      .subFields("statistics", "visitorCount", "starredCount")
      .subFields("mainImage", "uri", "mediaType", "v2")
      .subFields("car", "vin", "maker", "model", "initialRegistration", "mileage", "fuelType")
      // .subFields("steps", "amount", "stepStartTime", "stepEndTime")
      .subFields("bidderInfo", "bidderId", "bidderDisplayName")
      .subBuilder("address", builder =>
        builder.customType<Partial<PostalAddress>>().selectFields("zip", "city", "countryCode", "country")
      )
      .subFields("lastBid", "id", "amount", "count", "createdAt")
      .build(),
    // searchAuction: qb
    //   .query("searchAuctions", { includeCV: true })
    //   .args<SearchAuctionsArgs>("SearchAuctionsArgs", true)
    //   // .ListResult.allFields(true)
    //   .ListResult.selectFields(
    //     "auctionEndTime",
    //     "auctionType",
    //     // "dealerId",
    //     // "dealerName",
    //     // "hasAuctionEnded",
    //     "mainImage",
    //     "lastBid",
    //     "isProspectDeal",
    //     "id",
    //     "car",
    //     "reservePrice",
    //     // "startBid",
    //     "startPrice",
    //     "targetPrice",
    //     "ownerOrgName",
    //     "createdAt"
    //   )
    //   .subFields("statistics", "visitorCount", "starredCount")
    //   .subFields("mainImage", "uri", "mediaType", "v2")
    //   .subFields("car", "vin", "maker", "model")

    //   .subFields("lastBid", "amount")
    //   .subFields("bidderInfo", "bidderId", "bidderDisplayName")
    //   .build(),
    getAuctionsInWindow: qb
      .query("getAuctionsInWindow")
      .args<AuctionsInWindowArgs>("AuctionsInWindowArgs", true)
      .ListResult.selectFields("id", "auctionEndTime")
      .subFields("car", "vin", "maker", "model", "initialRegistration", "mileage", "fuelType")
      .subFields("lastBid", "id", "amount", "count", "createdAt")
      .subFields("bidderInfo", "bidderId", "bidderDisplayName")
      .subBuilder("address", builder =>
        builder.customType<Partial<PostalAddress>>().selectFields("zip", "city", "countryCode", "country")
      )
      .build(),

    // .ListResult.selectFields("id", "auctionEndTime")
    // .subFields("statistics", "visitorCount", "starredCount")
    // .subFields("mainImage", "uri", "mediaType", "v2")
    // .subFields("car", "vin", "maker", "model")
    // .subFields("lastBid", "amount")
    // .subFields("bidderInfo", "bidderId", "bidderDisplayName")
    // .subBuilder("address", builder =>
    //   builder.customType<Partial<PostalAddress>>().selectFields("zip", "city", "countryCode")
    // )
    // .build(),

    getAuctionsByVin: qb
      .query("getAuctionsByVin")
      .args<GetAuctionsByVinArgs>("GetAuctionsByVinArgs", true)
      .ListResult.selectFields(
        "id",
        "auctionEndTime",
        "auctionStartTime",
        "auctionType",
        "mainImage",
        "lastBid",
        "isProspectDeal",
        "statistics",
        "reservePrice",
        "startPrice",
        "targetPrice",
        "ownerOrgName",
        "createdAt"
      )
      // .subFields("mediaFiles", "uri", "name")
      .subFields("car", "vin", "maker", "model")
      .subFields("mainImage", "uri", "mediaType", "v2")
      .subFields("lastBid", "amount")
      .subFields("bidderInfo", "bidderDisplayName")
      .subFields("statistics", "visitorCount", "starredCount")
      // .subBuilder("_highestBidder", builder => {
      //   builder.selectFields("id", "firstname", "lastname", "username", "email", "phone");
      // })
      // .subFields("images", "isMainImage", "thumb", "src")
      .build(),
    getActiveAuctionByCarId: qb
      .query("getActiveAuctionByCarId", { allowNull: false })
      .args<GetActiveAuctionByCarIdArgs>("GetActiveAuctionByCarIdArgs")
      .PrimitiveResult<string>()
      .build()
  }))
  .build();

// Default direction is DESC
export function sortAuctionsByAuctionEndtime(
  auctions?: IAuctionListItemUI[],
  direction?: "ASC" | "DESC"
): IAuctionListItemUI[] {
  if (auctions) {
    return auctions.sort(sortFn("auctionEndTime", direction ?? "DESC"));
  } else return [];
}
