import { useCallback, useEffect } from "react";
import { environment } from "../core/environment";
declare global {
  interface Window {
    gtag: (command: string, eventName: string, eventParams?: Record<string, any>) => void;
    dataLayer: DataLayer;
  }
}

type DataLayer = {
  event: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
}[];
interface TrackEventOptions {
  eventName?: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
}
const getCookieByName = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2)
    return parts
      .pop()
      ?.split(";")
      .shift();
};
export const useGTMEventTracker = () => {
  const trackEvent = useCallback(
    ({ eventName = "customEvent", eventCategory, eventAction, eventLabel }: TrackEventOptions) => {
      const event = {
        event: eventName,
        eventCategory,
        eventAction,
        eventLabel
      };
      // console.log("🚀 ~ useGTMEventTracker ~ event:", event);

      // const cookiesAccepted = getCookieByName("cookiesAccepted") === "true";
      // const trackingActive = cookiesAccepted && environment.name === "production";
      const trackingActive = environment.name === "production";

      if (!trackingActive) {
        console.warn("Cookies not accepted. Event tracking disabled.");
        return;
      }
      //   console.log("🚀 ~ trackEvent ~ event:", event);
      const maxRetries = 3;
      let retryCount = 0;

      const pushToDataLayer = () => {
        if (!trackingActive) {
          console.warn("Cookies not accepted. Event tracking disabled.");
          return;
        }

        if (window.dataLayer) {
          console.log("Pushing event to dataLayer:", event);
          window.dataLayer.push(event);
        } else if (retryCount < maxRetries) {
          retryCount++;
          console.warn(`dataLayer is not defined. Retrying (${retryCount}/${maxRetries})...`);
          setTimeout(pushToDataLayer, 1000); // Retry after 1 second
        } else {
          console.error("Failed to push event to dataLayer after 3 retries.");
        }
      };

      const pushToGtag = () => {
        if (!trackingActive) {
          console.warn("Cookies not accepted. Event tracking disabled.");
          return;
        }

        if (typeof window.gtag === "function") {
          console.log("Pushing event to gtag:", event);
          window.gtag("event", eventName, {
            event_category: eventCategory,
            event_action: eventAction,
            event_label: eventLabel
          });
        } else {
          console.warn("gtag is not defined. Ensure that gtag.js is included.");
        }
      };

      pushToDataLayer();
      pushToGtag();
    },
    []
  );

  return trackEvent;
};

export function GoogleAnalytics(props: {}) {
  useEffect(() => {
    const cookiesAccepted = getCookieByName("cookiesAccepted");
    const trackingActive = cookiesAccepted && environment.name === "production";

    if (trackingActive) {
      // Load Google Analytics script
      const script1 = document.createElement("script");
      script1.async = true;
      script1.src = "https://www.googletagmanager.com/gtag/js?id=G-Q8WB922TDB";
      document.head.appendChild(script1);

      const script2 = document.createElement("script");
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-Q8WB922TDB', 
        { anonymize_ip: true,  
          'cookie_flags': 'SameSite=None;Secure',
          'cookie_domain': '.motorhammer.com',
          'linker': {
            'domains': ['www.motorhammer.com', 'app.motorhammer.com'],
            'enabled': false // Prevents URL parameter appending
            }});
      `;
      document.head.appendChild(script2);
    }
  }, []);

  return null;
}
