/**
 * UserDetails
 */
import {
  AccountContractTextResources,
  buildGraphCrudQuerySet,
  buildTextResources4Data,
  dataKitBuilder,
  PrivilegedUnit,
  PrivilegedUnitDataKit
} from "@emibee/lib-app-common";
import { AccidentType, CheckbookType, FuelType, IDealer } from "@mh/common";
import { DealerDataKit } from "../dealer/Dealer";
import { UserInfo, UserInfoDataKit } from "./UserInfo";

import { ChangeAccountArgs, GetAccountArgs, IAccountContract } from "@emibee/lib-shared";
import { dataNamespace, Domains } from "../../core/textResourceScopes";

export interface UserDetails extends UserInfo, IAccountContract {
  extPrivUnits?: PrivilegedUnit[];
  fullName: string;
  affiliateCode: string;
  // filterSettings?: FilterSettings;
  notificationSettings?: NotificationSettings;
  _authorizedFor: IDealer[];
}

const useDetailsTextResources = UserInfoDataKit.extendTextResources<UserDetails>({
  ...AccountContractTextResources,
  fullName: "Name",
  // affiliateCode: "Affiliate Code",
  // filterSettings: "Filter Settings",
  notificationSettings: "Notification Settings",
  _authorizedFor: "Authorized For"
});

export interface NotificationSettings {
  // enableWhatsapp: boolean;
  // whatsappConfirmed: boolean;
  newAuctionEmailNotifications: boolean;
  newProspectingEmailNotifications: boolean;
  outbidEmailNotifications: boolean;
  // newAuctionWhatsappNotifications: boolean;
  // newProspectingWhatsappNotifications: boolean;
  // outbidWhatsappNotifications: boolean;
}

const notificationSettingsTextResources = buildTextResources4Data<NotificationSettings>({
  scope: "Data",
  namespace: dataNamespace(Domains.account, "NotificationSettings"),
  resources: {
    newAuctionEmailNotifications: "New Auction Notification",
    newProspectingEmailNotifications: "New Prospecting Notification",
    outbidEmailNotifications: "Outbid Notification"
  }
});

export interface FilterSettings {
  filterMaker: boolean;
  filterModel: boolean;

  filterKm: boolean;
  minKm: number;
  maxKm: number;

  filterPrice: boolean;
  minPrice: number;
  maxPrice: number;

  filterTaxDeductible: boolean;
  filterCanBeExported: boolean;

  filterDealer: boolean;
  selectedMakers: [string];
  selectedModels: [string];

  temp_taxDeductibleValue?: boolean;
  selectedDealers?: IDealer[];

  filterVintage: boolean;
  minVintage: string;
  maxVintage: string;

  filterDetails: boolean;
  temp_filterCheckbook: CheckbookType[];
  temp_filterAccident: AccidentType[];
  temp_filterFuelType: FuelType[];
}

const NotificationSettingsDataKit = dataKitBuilder(notificationSettingsTextResources).build();

export interface ActivateAccountArgs {
  accountId: string;
  activate: boolean;
  stateReason?: string;
}

export interface DeleteAccountArgs {
  accountId: string;
}

export interface UpdateAccountLegacyArgs {
  // account: Partial<UserDetails>;
  id?: String;
  _authorizedFor?: IDealer[] | String[];
  isPremiumUser?: Boolean;
  v2?: Boolean;
}

export const UserDetailsDataKit = UserInfoDataKit.extend(useDetailsTextResources)
  .virtualField("fullName")
  .props({
    valueGetter: row => (row.firstname ? `${row.firstname} ${row.lastname}` : row.lastname)
  })
  .field("extPrivUnits")
  .props({ complex: PrivilegedUnitDataKit })
  // .field("filterSettings")
  // .props({ complex: FilterSettingsDataKit })
  .field("notificationSettings")
  .props({ complex: NotificationSettingsDataKit })
  .field("_authorizedFor")
  .props({ complex: DealerDataKit })
  .queries(qb => ({
    getAccount: qb
      .query("getAccount")
      .args<GetAccountArgs>("GetAccountInput", true)

      .Result.allFields(true)
      .subBuilder("_authorizedFor", builder =>
        builder
          .customType<Partial<IDealer>>()
          // .selectFields("id")
          .selectFields("id", "name", "contactPerson", "dealerCode", "phone", "email", "street", "zip", "city")
      )
      // .subBuilder("filterSettings", builder => builder.allFields(true).subFields("selectedDealers", "name"))
      .subBuilder("vehicleSearchCriteria", builder => builder.allFields(true))
      .subBuilder("extPrivUnits", builder =>
        builder
          .selectFields("id", "ownerOrgId", "roles", "ownerOrg")
          .subFields("roles", "name")
          .subFields("ownerOrg", "name")
      )
      .build(),
    activateAccount: qb
      .mutation("activateAccount")
      .args<ActivateAccountArgs>("ActivateAccountInput", true)
      // .Result.allFields(true)
      .Result.selectFields("id", "username", "deactivated", "state")
      .build(),
    deleteAccount: qb
      .mutation("deleteAccount")
      .args<DeleteAccountArgs>("DeleteAccountInput", true)
      .Result.selectFields("username", "deactivated", "status")
      .build(),
    changeAccount: qb
      .mutation("changeAccount")
      .args<ChangeAccountArgs>("ChangeAccountInput", true)
      .Result.selectFields("id", "cid", "ownerOrgId", "phone")
      .subFields("ownerOrg", "name")
      .build(),
    updateAccountLegacy: qb
      .mutation("updateAccountLegacy")
      .args<Partial<UserDetails>>("UpdateAccountLegacyInput", true)
      .extractArgFields(
        "id",
        "firstname",
        "lastname",
        "title",
        "email",
        "phone",
        "_authorizedFor",
        "isPremiumUser",
        "v2"
      )
      .Result.selectFields("username", "deactivated", "status", "isPremiumUser", "v2")
      .subBuilder("_authorizedFor", builder =>
        builder.customType<Partial<IDealer>>().selectFields("id", "name", "dealerCode")
      )
      .build()
  }))
  .build();

export const UserDetailsGraphQuerySet = buildGraphCrudQuerySet(
  UserDetailsDataKit.queries.getAccount,
  UserDetailsDataKit.queries.changeAccount
);
