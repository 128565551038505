import { buildTextResources4Enum, enumNamespace, localizeEnum, ResourceKey, useLocalize } from "@emibee/lib-app-common";
import {
  AuctionSoldState,
  AuctionState,
  AuctionType,
  ICarV2,
  SecurityAttributeScope,
  UserType,
  VehicleSearchCriteria
} from "@mh/common";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import RadioButtonUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Tooltip } from "@mui/material";
import { Domains } from "../core/textResourceScopes";
import { auctionSoldStateTextResources, IAuctionListItemUI } from "../data/auction";
import { IAuctionDetailsUI } from "../data/auction/AuctionDetails";
import { WidgetFilterDefinition } from "../exControls/widget/useWidgetFilter";

import { useMemo } from "react";

export function getSecurityAttributeScope(userType?: UserType) {
  if (userType === UserType.Dealer || userType === UserType.Admin || userType === UserType.System_Admin) {
    return SecurityAttributeScope.max;
  } else {
    return SecurityAttributeScope.min;
  }
}

export const isProspectingOrDutch = (auction: IAuctionDetailsUI | IAuctionListItemUI) => {
  return auction && (auction.auctionType === AuctionType.dutch || auction.auctionType === AuctionType.prospect);
};

export const hasAuctionEnded = (auction: IAuctionDetailsUI | IAuctionListItemUI) => {
  return auction && (auction.auctionEndTime < Date.now() || auction.state === AuctionState.ended);
};

export const isAuctionSold = (auction: IAuctionDetailsUI | IAuctionListItemUI) => {
  const startPrice = auction.startPrice || 0;
  return (
    auction &&
    hasAuctionEnded(auction) &&
    auction.lastBid &&
    (auction.lastBid.amount > startPrice ||
      auction.alternativePriceAccepted ||
      (isProspectingOrDutch(auction) && auction.isProspectDeal))
  );
};

export const AuctionFilterTextResources = buildTextResources4Enum<typeof AuctionFilterTypes>({
  scope: "Data",
  namespace: enumNamespace(Domains.auction, "AuctionType"),
  resources: {
    all: "All",
    pending: "Pending",
    ended: "Ended",
    open: "Open",
    deal: "Deal",
    noDeal: "NoDeal",
    starred: "Starred",
    vehicleSearchCriteria: "Custom"
  }
});

export enum AuctionFilterTypes {
  all,
  pending,
  deal,
  noDeal,
  open,
  ended,
  starred,
  vehicleSearchCriteria
  // prospectOpen,
  // prospectDeal,
  // prospectNoDeal
}

export const buildAuctionFilters = (args: {
  filters: AuctionFilterTypes[];
  initialActive?: AuctionFilterTypes[];
  staticFilters?: AuctionFilterTypes[];
  vsc?: VehicleSearchCriteria;
}): WidgetFilterDefinition<AuctionFilterTypes>[] => {
  return args.filters.map(type => {
    let title: ResourceKey;
    let icon: React.ReactNode;

    switch (type) {
      case AuctionFilterTypes.all:
        title = AuctionFilterTextResources.all;
        icon = <FilterListOffIcon />;
        break;
      case AuctionFilterTypes.pending:
        title = AuctionFilterTextResources.pending;
        icon = <ScheduleIcon />;
        break;
      case AuctionFilterTypes.ended:
        title = AuctionFilterTextResources.ended;
        icon = <HourglassTopIcon />;
        break;
      case AuctionFilterTypes.open:
        title = AuctionFilterTextResources.open;
        icon = <RadioButtonUnchecked />;
        break;
      case AuctionFilterTypes.deal:
        title = AuctionFilterTextResources.deal;
        icon = <CheckCircleOutlineIcon />;
        break;
      case AuctionFilterTypes.noDeal:
        title = AuctionFilterTextResources.noDeal;
        icon = <CancelIcon />;
        break;
      case AuctionFilterTypes.starred:
        title = AuctionFilterTextResources.starred;
        icon = <FavoriteIcon />;
        break;
      case AuctionFilterTypes.vehicleSearchCriteria:
        title = AuctionFilterTextResources.vehicleSearchCriteria;
        icon = <SavedSearchIcon />;
        break;
      default:
        throw new Error(`Unknown filter type: ${type}`);
    }
    return {
      type,
      title,
      icon,
      initialActive: args.initialActive?.includes(type),
      static: args.staticFilters?.includes(type),
      vsc: args.vsc
    };
  });
};

export function getAuctionSoldState(auction: IAuctionListItemUI) {
  // kann später einfach durch den AuctionState ersetzt werden (wenn legacy off ist)
  const hasAuctionEnded = auction.auctionEndTime < Date.now();

  const isBelowStartPrice =
    auction.lastBid &&
    auction.lastBid.amount !== null &&
    auction.lastBid.amount < (auction.startPrice || 0) &&
    (auction.alternativePriceAccepted === null || auction.alternativePriceAccepted !== false);

  // console.log("🚀 ~ getAuctionSoldState ~ isBelowStartPrice:", isBelowStartPrice);
  if (!hasAuctionEnded) return AuctionSoldState.running;
  const auctionSold = hasAuctionEnded && isAuctionSold(auction);

  if (
    (isProspectingOrDutch(auction) && auction.isProspectDeal === null) ||
    (!isProspectingOrDutch(auction) && isBelowStartPrice && !auctionSold)
  ) {
    return AuctionSoldState.open;
  } else if (
    (isProspectingOrDutch(auction) && auction.isProspectDeal === true) ||
    (!isProspectingOrDutch(auction) && auctionSold)
  ) {
    return AuctionSoldState.sold;
  } else if (
    (isProspectingOrDutch(auction) && auction.isProspectDeal === false) ||
    (!isProspectingOrDutch(auction) && !auctionSold && !isBelowStartPrice)
  ) {
    return AuctionSoldState.notSold;
  }
}

export function StatusIcon({
  auction,
  size = "small",
  tooltip
}: {
  auction: IAuctionListItemUI;
  size?: "xs" | "small" | "medium";
  tooltip?: boolean;
}) {
  const localize = useLocalize();

  const iconElement = useMemo(() => {
    const auctionSoldState = getAuctionSoldState(auction);

    let icon: JSX.Element;
    switch (auctionSoldState) {
      case AuctionSoldState.open:
        icon = <RadioButtonUnchecked sx={{ fontSize: size, color: "lightgray" }} />;
        break;
      case AuctionSoldState.sold:
        icon = <CheckCircleOutlineIcon sx={{ fontSize: size, color: "green" }} />;
        break;
      case AuctionSoldState.notSold:
        icon = <CancelIcon sx={{ fontSize: size, color: "red" }} />;
        break;
      default:
        icon = <AccessTimeIcon sx={{ fontSize: size }} />;
        break;
    }

    if (tooltip) {
      const tooltipTitle = localizeEnum(AuctionSoldState, auctionSoldStateTextResources, auctionSoldState, localize);
      return (
        <Tooltip placement="left" title={tooltipTitle}>
          {icon}
        </Tooltip>
      );
    }

    return icon;
  }, [auction, size, tooltip, localize]);

  return iconElement;
}

export function filterAuctions(auction: IAuctionListItemUI, type: AuctionFilterTypes[], vsc?: VehicleSearchCriteria) {
  const hasAuctionEnded = auction.auctionEndTime < Date.now();
  const isPending = type?.includes(AuctionFilterTypes.pending) && !hasAuctionEnded;
  const isEnded = type?.includes(AuctionFilterTypes.ended) && hasAuctionEnded;

  const isOpen = type?.includes(AuctionFilterTypes.open) && getAuctionSoldState(auction) === AuctionSoldState.open;
  const isDeal = type?.includes(AuctionFilterTypes.deal) && getAuctionSoldState(auction) === AuctionSoldState.sold;
  const isNoDeal =
    type?.includes(AuctionFilterTypes.noDeal) && getAuctionSoldState(auction) === AuctionSoldState.notSold;

  const isStarredFilter = type?.includes(AuctionFilterTypes.starred);
  const isStarred = isStarredFilter && auction.myFavorite; // todo: in anderem Ticket implementieren

  // return (!type || isPending || isEnded || isOpen || isDeal || auctionSold || isNoDeal || !auctionSold).valueOf();

  // return (
  //   !type ||
  //   (type.includes(AuctionFilterTypes.pending) && isPending) ||
  //   (type.includes(AuctionFilterTypes.ended) && isEnded) ||
  //   isOpen ||
  //   isDeal ||
  //   isNoDeal ||
  //   isStarred
  // ).valueOf();
  const baseFilter = (
    type.length < 1 ||
    (type.includes(AuctionFilterTypes.pending) && isPending) ||
    (type.includes(AuctionFilterTypes.ended) && isEnded) ||
    isOpen ||
    isDeal ||
    isNoDeal
  )
    // ||
    // isStarred
    .valueOf();

  const vehicleFilter = type.includes(AuctionFilterTypes.vehicleSearchCriteria)
    ? doesAuctionMatchCriteria(auction, vsc)
    : true;
  // Starred muss ein exclusive filter sein.
  return isStarredFilter ? isStarred && baseFilter : baseFilter && vehicleFilter;
  // return baseFilter;
}
export function hasUserWonAuction({ userId, auction }: { userId?: string; auction: IAuctionDetailsUI }) {
  if (!userId) return false;

  if (isProspectingOrDutch(auction)) {
    return auction.isProspectDeal || false;
  } else {
    return auction.lastBid.bidderId === userId;
  }
}

/**
 * Parst ein Datum, welches entweder als "MM/YYYY" oder in einem Standardformat vorliegen kann.
 * Falls das Datum ungültig ist, wird undefined zurückgegeben.
 */
function parseDateString(dateStr: string): Date | undefined {
  if (!dateStr) return undefined;
  // Falls im Format "MM/YYYY", einen Tag (z.B. 01) voranstellen:
  const match = dateStr.match(/^(\d{2})\/(\d{4})$/);
  let formatted: string;
  if (match) {
    // match[1] = Monat, match[2] = Jahr
    formatted = `${match[2]}-${match[1]}-01`;
  } else {
    formatted = dateStr;
  }
  const date = new Date(formatted);
  console.log("🚀 ~ parseDateString ~ date:", date);
  console.log("🚀 ~ parseDateString ~ formatted:", formatted);
  return isNaN(date.getTime()) ? undefined : date;
}

/**
 * Prüft, ob ein einzelnes Fahrzeug die Suchkriterien erfüllt.
 */
function doesVehicleMatchCriteria(vehicle: ICarV2, criteria: VehicleSearchCriteria): boolean {
  // Maker prüfen, falls makerList gesetzt ist
  if (criteria.makerList && criteria.makerList.length > 0) {
    if (vehicle.maker == null || !criteria.makerList.includes(vehicle.maker)) {
      return false;
    }
  }

  // Prüfe mileage (Verwendung eines Nullchecks)
  const mileage = vehicle.mileage == null ? 0 : vehicle.mileage;
  if (criteria.mileageMin != null && mileage < criteria.mileageMin) return false;
  if (criteria.mileageMax != null && mileage > criteria.mileageMax) return false;

  if (criteria.vintageMin && vehicle.initialRegistration != null) {
    const vehicleDate = parseDateString(vehicle.initialRegistration);
    const filterMinDate = parseDateString(criteria.vintageMin);

    // Falls eines der beiden Daten ungültig ist, überspringe den Vergleich
    if (vehicleDate && filterMinDate && vehicleDate < filterMinDate) return false;
  }
  if (criteria.vintageMax && vehicle.initialRegistration != null) {
    const vehicleDate = parseDateString(vehicle.initialRegistration);
    const filterMaxDate = parseDateString(criteria.vintageMax);
    if (vehicleDate && filterMaxDate && vehicleDate > filterMaxDate) return false;
  }

  if (criteria.vintageMax && vehicle.initialRegistration != null) {
    if (new Date(vehicle.initialRegistration) > new Date(criteria.vintageMax)) return false;
  }

  // // Prüfe steuerliche Absetzbarkeit
  // if (criteria.taxDeductible != null && vehicle.vatDeductible !== criteria.taxDeductible) return false;

  // // Prüfe Exportierbarkeit
  // if (criteria.allowExport != null && vehicle.exportable !== criteria.allowExport) return false;

  // Prüfe accidentFilter (sofern gesetzt)
  if (criteria.accidentFilter && criteria.accidentFilter.length > 0) {
    if (vehicle.accidentalDamage == null || !criteria.accidentFilter.includes(vehicle.accidentalDamage)) {
      return false;
    }
  }

  // Prüfe fuelTypeFilter
  if (criteria.fuelTypeFilter && criteria.fuelTypeFilter.length > 0) {
    if (vehicle.fuelType == null || !criteria.fuelTypeFilter.includes(vehicle.fuelType)) return false;
  }

  // Weitere Kriterien (z.B. isDefault, checkbookFilter) können analog geprüft werden.
  console.log("filter should return true...");

  return true;
}

/**
 * Prüft, ob eine Auktion die Suchkriterien erfüllt.
 * - Falls auction.car existiert, wird dieses Fahrzeug geprüft.
 * - Falls nicht, muss jedes Fahrzeug im carBundle die Kriterien erfüllen.
 * Zusätzlich wird der Abstand (distance) gegen ein mögliches Radius-Kriterium geprüft.
 */
function doesAuctionMatchCriteria(auction: IAuctionListItemUI, criteria?: VehicleSearchCriteria): boolean {
  if (!criteria) return true;

  // Filter für den Umkreis, sofern gesetzt
  const distance = auction.distance;
  const radius = criteria.radius;
  if (radius && distance && distance > radius) {
    return false;
  }

  // Prüfe Preis
  const actBid = auction.startPrice || auction.lastBid.amount || 0;

  if (criteria.priceMin != null && actBid < criteria.priceMin) return false;
  if (criteria.priceMax != null && actBid > criteria.priceMax) return false;

  if (auction.car) {
    return doesVehicleMatchCriteria(auction.car, criteria);
  } else if (auction.carBundle && auction.carBundle.length > 0) {
    // Falls ein Fahrzeug im Bundle die Kriterien nicht erfüllt, wird die Auktion nicht angezeigt.
    return auction.carBundle.every(vehicle => doesVehicleMatchCriteria(vehicle, criteria));
  }

  // Falls weder car noch carBundle befüllt sind, kannst du die Auktion ausschließen:
  return false;
}

// Beispiel: Filterung der Ergebnisse anhand der Suchkriterien
export function filterAuctionsBySearchCriteria(auction: IAuctionListItemUI, criteria: VehicleSearchCriteria) {
  // return auction.filter(auction => doesAuctionMatchCriteria(auction, criteria));
  return doesAuctionMatchCriteria(auction, criteria);
}
