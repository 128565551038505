import { buildTextResourcesMH, Domains, enumNamespace } from "../../core/textResourceScopes";
import { buildTextResources4Enum } from "@emibee/lib-app-common";
import { OrganizationKind } from "@emibee/lib-shared";

export const textResources = buildTextResourcesMH({
  scope: "Views",
  namespace: Domains.organization,
  resources: {
    pageTitle: "Organizations",
    routeListText: "Organizations",
    routeDetailsText: "Organization",
    btnTextNew: "New Organization",
    sectionOrganizationData: "Organization",
    sectionTasks: "Tasks",
    sectionActions: "Actions",
    sectionPendingInvitations: "Pending Invitations",
    sectionTabDetails: "Details",
    sectionTabSettings: "Legacy Attributes",
    sectionTabActions: "Actions",
    sectionTabContacts: "Contacts",
    sectionTabAccounts: "Accounts",
    sectionTabRoles: "Security Roles",
    sectionTabSubunits: "Subunits",
    sectionTabFiles: "Files",
    sectionTabAuctions: "Auctions",
    sectionTabAudit: "Audit",
    fieldnamePrimaryContact: "Primary Contact",

    changeDateRange: "Change Date",

    // subSectionAddress
    sectionAddresses: "Addresses",
    actionEditAddressTitle: "Edit Address",

    labelBlacklistEntries: "Blacklist: {{count}} entries",

    //  sectionMainAddress: "Main Address",
    //  actionEditMainAddressTitle: "Edit Address",
    //  sectionBillingAddress: "Billing Address",
    //  actionEditBillingAddressTitle: "Edit Billing Address",
    //  sectionPostalAddress: "Postal Address",
    //  actionEditPostalAddressTitle: "Edit Postal Address",

    // Action - AddAccount
    actionAddAccountTitle: "Add Account",
    actionAddAccount: "Add",
    actionAddAccountDescription: "Add an existing account to the organization.",

    // Action - Delete Account
    actionDeletePrivUnitAccountSuccessToast: "Account successfully removed.",
    actionDeletePrivUnitAccountQueryTitle: "Remove Account",
    actionDeletePrivUnitAccountQueryText: "Remove account from organization?",

    // Action - InviteMember
    actionInviteMemberTitle: "Invite Member",
    actionInviteMember: "Invite",
    actionInviteMemberDescription: "Invite new members to the organization.",

    actionDeleteInvitationSuccessToast: "Invitation sucessfully deleted",
    actionDeleteInvitationQueryTitle: "Delete Invitation",
    actionDeleteInvitationQueryText: "Do you want to delete this invitation?",

    // Action - AddRoles
    actionAddRolesTitle: "Add Security Roles",
    actionAddRoles: "Add",
    actionAddRolesDescription: "Authorize the organization by adding security roles.",

    // Action - Delete Role
    actionDeleteRoleSuccessToast: "Security Role successfully removed.",
    actionDeleteRoleQueryTitle: "Remove Security Role",
    actionDeleteRoleQueryText: "Remove Security Role from organization?",

    // Action - Create Subunit
    actionCreateSubunitTitle: "Create Subunit",
    actionCreateSubunit: "Create",
    actionCreateSubunitDescription: "Create a new subunit and add to the organization.",

    // Action - Delete Subunit
    actionDeleteSubunitSuccessToast: "Subunit successfully removed.",
    actionDeleteSubunitQueryTitle: "Remove Subunit",
    actionDeleteSubunitQueryText: "Remove Subunit from organization?",

    // Action - Delete Organization
    actionDeleteOrganizationTitle: "Delete Organization",
    actionDeleteOrganization: "Delete",
    actionDeleteOrganizationDescription: "Deletes this organization and resets all member accounts.",
    actionDeleteOrganizationQueryTitle: "Delete Organization",
    actionDeleteOrganizationQueryText: "Delete organization and reset all member accounts?",
    actionDeleteOrganizationSuccessToast: "Organization successfully deleted.",

    // Picker & Dialog
    accountPickerTitle: "Available Accounts",
    securityRolePickerTitle: "Available Security Roles",
    securityRolePickerAcceptBtn: "Accept",
    orgAccountCreateTitle: "Add Account",
    orgAccountEditTitle: "Edit Organization Account",
    subunitDialogCreateTitle: "Create new Subunit",
    subunitDialogEditTitle: "Edit Subunit",

    inviteMemberDialogTitle: "Invite Member",
    actionInviteMemberSuccessToast: "Invitation(s) successfully send",
    addEmailButton: "Add eMail",
    deleteEmailButton: "Remove eMail",
    inviteMembersButton: "Invite",

    // Action - Edit
    actionEditOrganizationTitle: "Edit",
    actionEditOrganizationContactsTitle: "Edit Contacts",
    dialogEditTitle: "Edit Organization",
    dialogCreateTitle: "Create Organization",
    contactInfoAccountPickText: "Choose Account",

    // PostalAddress - Edit
    // mainAddressEdit: "Edit Main Address",
    editAddressesTitle: "Edit Addresses",
    tabMainAddress: "Main Address",
    tabBillingAddress: "Billing Address",
    clearBillingAddressButton: "Clear",

    // ContactInfo - Edit
    actionDeleteContactInfoSuccessToast: "Contact was successfully removed",
    actionDeleteContactInfoQueryTitle: "Remove Contact",
    actionDeleteContactInfoQueryText: "Do you want to remove this contact from the organization?",
    editContacts: "Edit Contacts",
    actionAddContactsButton: "Add Contact",
    validationRolesAlreadyUsed: {
      fallback: "{{roles}} already in use",
      args: ["roles"]
    },
    orgDetailsLinkText: "Details",

    // OrgTypeChange
    actionChangeOrgTypeSuccessToast: "OrgType was successfully changed",
    actionChangeOrgTypeActionText: "Change organization type",
    actionChangeOrgTypeToResellerDescription: "Change the type of the organization to reseller",
    actionChangeOrgTypeToDealerDescription: "Change the type of the organization to dealer",
    actionChangeOrgTypeQueryTitle: "Change organization type",
    actionChangeOrgTypeQueryText: "Do you want to change the organization type?",
    changeOrgTypeTitle: "Change organization type"
  }
});

export const organizationKindTextResources = buildTextResources4Enum<typeof OrganizationKind>({
  scope: "Data",
  namespace: enumNamespace(Domains.organization, "OrganizatinKind"),
  resources: {
    default: "Default",
    subunit: "Subunit"
  }
});
