import {
  AuthorModus,
  ClientService,
  ErrorType,
  ILocalizationService,
  LocalizationServiceID,
  localizeFallback,
  ResourceKeyInfo,
  ResourceKeyReference,
  TextResDataKit,
  toResourceKeyInfo
} from "@emibee/lib-app-common";
import i18n, { BackendModule, MultiReadCallback } from "i18next";
//import Backend from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector";
//import { nodesToString } from 'react-i18next/dist/es/Trans';
import BackendAdapter from "i18next-multiload-backend-adapter";
import React from "react";
import { initReactI18next, Trans } from "react-i18next";

import { Locale } from "@mh/common";
import { environment } from "./environment";
import { MHClientService } from "./MHClientService";

function buildBackend(client: ClientService): BackendModule {
  const load = (locales: readonly string[], namespaces: readonly string[], callback: MultiReadCallback) => {
    logger.info("LocalizationService", `Namespaces [${namespaces}]([${locales}]) requested.`);
    // callback(null, {});
    client
      .query(TextResDataKit.queries.loadTextResources, { locales, namespaces }, { fetchPolicy: "no-cache" })
      .then(response => {
        if (response.result) {
          const data: any = {};
          response.result.locales.forEach(l => {
            data[l.locale] = l.resources.reduce((p, c) => {
              if (namespaces.includes(c.namespace)) {
                p[c.namespace] = c.resources;
              } else {
                // add directly
                i18n.addResourceBundle(l.locale, c.namespace, c.resources);
                logger.info("LocalizationService", `Namespace ${c.namespace}(${l.locale}) added by wildcard.`);
              }
              return p;
            }, {} as any);
          });

          callback(null, data);

          logger.info("LocalizationService", `Namespaces ${namespaces}(${locales}) loaded.`);
        } else {
          logger.error("LocalizationService", response.error);
          callback(new Error(response.error?.message), null);
        }
      });
  };
  return {
    type: "backend",
    init: function(services, backendOptions, i18nextOptions) {
      /* use services and options */
    },
    read: function(lang, namespace, callback) {
      load([lang], [namespace], callback);
    },

    // optional
    readMulti: function(langs, namespaces, callback) {
      load(langs, namespaces, callback);
    },

    // only used in backends acting as cache layer
    // save: function(language, namespace, data) {
    //   // store the translations
    // },

    create: function(languages, namespace, key, fallbackValue) {
      if (!languages.every(lang => i18n.hasResourceBundle(lang, namespace))) {
        console.log("i18next: Missing namespace:", languages, namespace);
      }
    }
  };
}

// moved to app/src/data/common.ts:2:26  due to error:
// ../app/src/data/common.ts:2:26 - error TS6142: Module '../core/LocalizationService' was resolved to '/Users/jayd/Work/Projects/Motorhammer/motorhammer/packages/app/src/core/LocalizationService.tsx', but '--jsx' is not set.
// export enum Language {
//   German = "de",
//   English = "en",
//   Polish = "pl"
// }

const ACTIVE = process.env.REACT_APP_TEXT_RES !== "false";

export class LocalizationService implements ILocalizationService {
  private static _availableLangs = Object.values(Locale) as Locale[];
  private _authorModus: AuthorModus;

  constructor(private _client: MHClientService) {
    i18n
      // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
      // learn more: https://github.com/i18next/i18next-http-backend
      .use(BackendAdapter)
      // detect user language
      // learn more: https://github.com/i18next/i18next-browser-languageDetector
      .use(LanguageDetector)
      // pass the i18n instance to react-i18next.
      .use(initReactI18next)
      // init i18next
      // for all options read: https://www.i18next.com/overview/configuration-options
      .init({
        backend: {
          backend: buildBackend(_client)
        },
        ns: [],
        fallbackLng: Locale.German,
        supportedLngs: LocalizationService._availableLangs,
        // debug: true,

        interpolation: {
          escapeValue: false // not needed for react as it escapes by default
        },
        saveMissing: environment.name === "development"
      });
    this._authorModus = this._client.profileService.getScope("localizationService")?.authorModus || AuthorModus.default;
  }

  get id() {
    return LocalizationServiceID;
  }

  get authorModus() {
    return this._authorModus;
  }

  set authorModus(value: AuthorModus) {
    this._authorModus = value;
    this._client.profileService.setScope("localizationService", { authorModus: value });
    this._client.notifyChange("Language");
  }

  localizeF(namespace: string) {
    return (keyInfo: ResourceKeyInfo) => this._localize(keyInfo, namespace) ?? "";
  }

  localize(res?: ResourceKeyReference | ErrorType | string, args?: { [name: string]: any }) {
    if (res === undefined) return "";
    else if (typeof res === "string") return res;
    const key = toResourceKeyInfo(res, args);
    return (ACTIVE && this._localize(key)) || localizeFallback(key);
  }

  private _localize(res: ResourceKeyInfo, namespace?: string) {
    let authorText: string | undefined;
    if (this._authorModus > 0) {
      if (this._authorModus === AuthorModus.keyOnly) return res.key;
      else if (this._authorModus === AuthorModus.keyAndNamespace) return `${res.namespace}.${res.key}`;
      else if (this._authorModus === AuthorModus.keyAndNamespaceAndText) {
        authorText = `${res.namespace}.${res.key}`;
      }
    }

    let { key, args, trans } = res;
    namespace = namespace ?? res.namespace ?? "??";

    if (trans && !authorText) {
      trans = typeof trans === "function" ? trans(args || {}) : trans;

      if (!res.fallback) {
        key = `${key} (Translation-String)`;
        //nodesToString(trans, reactI18nextOptions)
      }

      return (
        <Trans
          i18nKey={key}
          ns={namespace}
          values={args}
          count={((args || {}) as any).count}
          defaults={res.fallback}
          components={trans as any}
        />
        //   {trans}
        // </Trans>
      );
    }
    //console.log(key, args, trans)
    const val = i18n.getFixedT(null, namespace)(key!, "", args);
    return authorText ? `${val ? val : res.fallback} (${authorText})` : val;
  }

  async fetchNamespaces(namespace: string | string[]) {
    if (ACTIVE) {
      return i18n.loadNamespaces(namespace);
    }
  }

  get availableLangs() {
    return LocalizationService._availableLangs;
  }

  get activeLang() {
    return i18n.language;
  }

  switchLang(lang: string) {
    // todo: @michi können wir hier noch das "lang" cookie setzen? dann ist der switch auch in der Legacy
    i18n
      .changeLanguage(lang)
      .then(() => this._client.notifyChange("Language"))
      .catch(error => {
        // don't know if this ever rejects
        logger.error("LocalizationService", error);
      });
  }
}
