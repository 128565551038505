import { buildTextResources, Localizer, useLocalizationService, useLocalize } from "@emibee/lib-app-common";
import { HP_KW_FACTOR } from "@mh/common";
import formatDistance from "date-fns/formatDistance";
import { de, enGB } from "date-fns/locale";
import React, { ReactNode } from "react";

export const defaultDateOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit"
};

function getDateFnsLocale(locale: string) {
  if (locale === "de") return de;
  else return enGB;
}

const textResources = buildTextResources({
  scope: "UI",
  namespace: "Formatters",
  resources: {
    boolYes: "Yes",
    boolNo: "No",
    boolTrue: "True",
    boolFalse: "False",
    isSoldFalse: "Not Sold",
    isSoldTrue: "Sold",
    isSoldOpen: "Open",
    mileageKm: "km",
    enginePowerHp: "HP",
    enginePowerKw: "kW",
    qccNotChecked: "not checked",
    qccCheckedOk: "Ok",
    qccCheckedNOk: "not ok",
    displacement: "ccm"
  }
});
type DateFormatType = "date" | "dateTime" | "time";
export const DateFormatter = (locale: string, formatType?: DateFormatType) => (date?: number | Date) => {
  try {
    const dateD = typeof date === "number" ? new Date(date) : date;
    formatType = !formatType ? "date" : formatType;
    if (formatType === "date") {
      return dateD ? dateD.toLocaleDateString(locale, defaultDateOptions) : undefined;
    } else if (formatType === "dateTime") {
      return dateD ? `${dateD.toLocaleTimeString(locale, defaultDateOptions)}` : undefined;
    } else if (formatType === "time") {
      return dateD ? `${dateD.toLocaleTimeString(locale)}` : undefined;
    }
  } catch (e) {
    console.log("Invalid input for DateFormatter", date, e);
  }
};

export const MileageFormatter = (locale: string, localize: Localizer, noSuffix?: "noSuffix") => (milage?: number) => {
  const suffix = noSuffix ? "" : ` ${localize(textResources.mileageKm)}`;
  return milage ? Number(milage).toLocaleString(locale) + suffix : undefined;
};

export const PriceFormatter = (locale: string) => (price?: number) => {
  const currency = "EUR";
  return price ? Number(price).toLocaleString(locale) + ` ${currency}` : undefined;
};
export const FloatFormatter = (locale: string) => (val?: number) => {
  return val ? Number(val).toLocaleString(locale) : undefined;
};

type BoolFormatType = "yesNo" | "isSold";
export const BooleanFormatter = (locale: string, localize: Localizer, formatType?: BoolFormatType) => (
  input?: boolean
) => {
  let returnValue = undefined;
  if (input !== undefined && input !== null) {
    switch (formatType) {
      case "yesNo":
        returnValue = input === true ? localize(textResources.boolYes) : localize(textResources.boolNo);
        break;
      case "isSold":
        returnValue = input === true ? localize(textResources.isSoldTrue) : localize(textResources.isSoldFalse);
        break;
      default:
        returnValue = input === true ? localize(textResources.boolTrue) : localize(textResources.boolFalse);
        break;
    }
  } else {
    if (formatType === "isSold") {
      returnValue = localize(textResources.isSoldOpen);
    }
  }
  // textResources can only be string
  return returnValue?.toString();
};

export const EnginePowerFormatter = (locale: string, localize: Localizer) => (hp?: number) => {
  if (hp) {
    // const calcKw = car && car.kw ? car.kw : String(Math.round(hp / 1.341));
    const calcKw = String(Math.round(hp / HP_KW_FACTOR));
    return `${hp} ${localize(textResources.enginePowerHp)} (${calcKw} ${localize(textResources.enginePowerKw)})`;
  } else {
    return "-";
  }
};

export const DisplacementFormatter = (locale: string, localize: Localizer) => (displacement?: number) => {
  if (displacement) {
    // const calcKw = car && car.kw ? car.kw : String(Math.round(hp / 1.341));

    return `${Number(displacement).toLocaleString(locale)} ${localize(textResources.displacement)}`;
  } else {
    return "-";
  }
};
export const QuickCarCheckFormatter = (locale: string, localize: Localizer) => (check?: number | boolean) => {
  let returnValue = undefined;
  switch (check) {
    case 0:
      returnValue = `${localize(textResources.qccNotChecked)}`;
      break;
    case 1:
      returnValue = `${localize(textResources.qccCheckedOk)}`;
      break;
    case 2:
      returnValue = `${localize(textResources.qccCheckedNOk)}`;
      break;
    case true:
      returnValue = `${localize(textResources.qccCheckedOk)}`;
      break;
    case false:
      returnValue = `${localize(textResources.qccCheckedNOk)}`;
      break;
    case null:
      returnValue = `${localize(textResources.qccNotChecked)}`;
      break;
  }
  return returnValue as string;
};

export const DateDistance = (locale: string) => (date?: number | Date) => {
  try {
    return date
      ? formatDistance(date, Date.now(), {
          addSuffix: true,
          locale: getDateFnsLocale(locale)
        })
      : undefined;
  } catch (e) {
    console.log("Invalid input for DateDistance", e);
  }
};

export const DateAndDistanceFormatter = (locale: string) => (date?: number | Date) => {
  try {
    return date ? `${DateFormatter(locale)(date)} (${DateDistance(locale)(date)})` : undefined;
  } catch (e) {
    console.log("Invalid input for DateFormatter", date, e);
  }
};

type NumberToStringFormatter = (val: number | undefined) => string | undefined;
type DateToStringFormatter = (val: number | Date | undefined) => string | undefined;
type StringToStringFormatter = (val: string | string[] | undefined) => string | undefined;
// type BooleanToStringFormatter = (val: boolean | undefined) => ReactNode;
type BooleanToStringFormatter = (val: boolean | undefined) => string | undefined;
type QccToStringFormatter = (val: boolean | number | undefined) => ReactNode;

interface FormattersCache {
  date?: DateToStringFormatter;
  dateDistance?: DateToStringFormatter;
  dateAndDistance?: DateToStringFormatter;
  float?: NumberToStringFormatter;
  price?: NumberToStringFormatter;
  mileage?: NumberToStringFormatter;
  boolean?: BooleanToStringFormatter;
  hpKw?: NumberToStringFormatter;
  displacement?: NumberToStringFormatter;
  qcc?: QccToStringFormatter;
}
export interface Formatters {
  date: (formatType?: DateFormatType) => DateToStringFormatter;
  dateDistance: () => DateToStringFormatter;
  dateAndDistance: () => DateToStringFormatter;
  float: () => NumberToStringFormatter;
  price: () => NumberToStringFormatter;
  mileage: (noSuffix?: "noSuffix") => NumberToStringFormatter;
  boolean: (formatType?: BoolFormatType) => BooleanToStringFormatter;
  hpKw: () => NumberToStringFormatter;
  displacement: () => NumberToStringFormatter;
  qcc: () => QccToStringFormatter;
}

function buildFormatterBuilder(locale: string, localize: Localizer, opts?: BoolFormatType): Formatters {
  const formatters: FormattersCache = {};
  const formattersWithKey: Record<string, Function | undefined> = {};

  const getFormatter = <N extends keyof FormattersCache>(name: N, val: FormattersCache[N], key?: string) => {
    if (key) {
      const accessKey = `${name}#${key}`;
      let formatter = formattersWithKey[accessKey];
      if (!formatter) {
        formatter = val;
        formattersWithKey[accessKey] = formatter;
      }
      return (formatter as FormattersCache[N])!;
    } else {
      let formatter = formatters[name];
      if (!formatter) {
        formatter = val;
        formatters[name] = formatter;
      }
      return formatter!;
    }
  };

  return {
    mileage: (noSuffix?: "noSuffix") => getFormatter("mileage", MileageFormatter(locale, localize, noSuffix), noSuffix),
    price: () => formatters.price ?? getFormatter("price", PriceFormatter(locale)),
    float: () => formatters.float ?? getFormatter("float", FloatFormatter(locale)),
    boolean: (formatType?: BoolFormatType) =>
      getFormatter("boolean", BooleanFormatter(locale, localize, formatType), formatType),
    hpKw: () => formatters.hpKw ?? getFormatter("hpKw", EnginePowerFormatter(locale, localize)),
    displacement: () =>
      formatters.displacement ?? getFormatter("displacement", DisplacementFormatter(locale, localize)),
    qcc: () => formatters.qcc ?? getFormatter("qcc", QuickCarCheckFormatter(locale, localize)),
    date: (formatType?: DateFormatType) => getFormatter("date", DateFormatter(locale, formatType), formatType),
    dateDistance: () => formatters.dateDistance ?? getFormatter("dateDistance", DateDistance(locale)),
    dateAndDistance: () =>
      formatters.dateAndDistance ?? getFormatter("dateAndDistance", DateAndDistanceFormatter(locale))
  };
}

export function useFormatters() {
  const localizationService = useLocalizationService();
  const localize = useLocalize();

  const [formatters] = React.useState(() => buildFormatterBuilder(localizationService.activeLang, localize));
  return formatters;
}
