/**
 * UserInfo
 */

import {
  buildGraphCrudQuerySet,
  buildTextResources4Data
} from "@emibee/lib-app-common";
import { GetAccountArgs } from "@emibee/lib-shared";
import { FilterSettingsLegacy, VehicleSearchCriteria } from "@mh/common";
import { dataNamespace, Domains } from "../../core/textResourceScopes";
import { UniqueNameFields } from "../../pages/account/useUniqueNameValidatorBuilder";
import { CheckAddressArgs, CheckAddressResult } from "../../tools/useAddressValidate";
import { VehicleSearchCriteriaDataKit } from "./UserMin";
import { UserOverview, UserOverviewDataKit } from "./UserOverview";

export interface UserInfo extends UserOverview {
  // company?: Company;
  // _files?: IFileRef[];
  // filterSettings?: FilterSettingsLegacy;
  vehicleSearchCriteria?: VehicleSearchCriteria;
}

const userInfoTextResources = UserOverviewDataKit.extendTextResources<UserInfo>({
  // title: "Title",
  // filterSettings: "Filter Settings",
  vehicleSearchCriteria: "Filter Settings"
  // _files: "Files",
  // company: "Company"
});

export enum PersonalTitle {
  Mr = 0,
  Ms = 1
}

export interface Location {
  type: string;
  coordinates: [number, number];
}

const filterSettingsLegacyTextResources = buildTextResources4Data<FilterSettingsLegacy>({
  scope: "Data",
  namespace: dataNamespace(Domains.account, "FilterSettings"),
  resources: {
    selectedMakers: "Selected Makers",
    selectedModels: "Selected Models",
    temp_taxDeductibleValue: "Tax deductible",
    filterModel: "Filter Model",
    filterMaker: "Filter Maker",
    filterCanBeExported: "Can Be Exported",
    filterDealer: "Filter Dealer",
    filterDetails: "Filter Details",
    filterPrice: "Filter Price",
    filterTaxDeductible: "Filter Tax Deductible",
    filterKm: "Filter Mileage",
    maxKm: "Max Mileage",
    minKm: "Min Mileage",
    maxPrice: "Max Price",
    minPrice: "Min Price",
    selectedDealers: "Selected Dealers",
    filterVintage: "Filter Vintage",
    minVintage: "Min Vintage",
    maxVintage: "Max Vintage",
    temp_filterCheckbook: "Filter Checkbook",
    temp_filterAccident: "Filter Accident",
    temp_filterFuelType: "Filter Fueltype",
    radius: "Radius",
    filterRadius: "Filter Radius"
  }
});

// const FilterSettingsLegacyDataKit = dataKitBuilder(filterSettingsLegacyTextResources)
//   .fieldType("number", "radius", "minKm", "maxKm", "minPrice", "maxPrice", "minVintage", "maxVintage")
//   .fieldType(
//     "boolean",
//     "filterCanBeExported",

//     "filterTaxDeductible",
//     "filterDetails",
//     "filterPrice",
//     "filterKm",
//     "filterVintage",
//     "filterModel",
//     "filterMaker",
//     "filterDealer",
//     "filterRadius"
//   )
//   .field("temp_filterFuelType")
//   .props({
//     options: buildSelectOptions(FuelType, fuelTypeTextResources)
//   })
//   .field("temp_filterCheckbook")
//   .props({
//     options: buildSelectOptions(CheckbookType, checkbookTypeTextResources)
//   })
//   .field("temp_filterAccident")
//   .props({
//     options: buildSelectOptions(AccidentType, accidentTypeTextResources)
//   })
//   .field("selectedDealers")
//   .props({ complex: DealerDataKit })
//   .build();

export interface Company {
  name: string;
  vatId: string;
  street: string;
  zip: string;
  city: string;
  country: string;
  countryCode: string;
  location: string;
  state: string;
  stateCode: string;
  formatted_address: string;
}

export interface ChangeAccountPersonalDataArgs extends Partial<UserInfo> {
  id: string;
}

export interface UniqueNameArgs {
  field?: string;
  fieldType?: keyof UniqueNameFields;
}

export const UserInfoDataKit = UserOverviewDataKit.extend(userInfoTextResources)
  // .field("filterSettings")
  // .props({ complex: FilterSettingsLegacyDataKit })
  .field("vehicleSearchCriteria")
  .props({ complex: VehicleSearchCriteriaDataKit })
  .queries(qb => ({
    getAccount: qb
      .query("getAccount")
      .args<GetAccountArgs>("GetAccountInput", true)
      .Result.allFields(true)
      .subBuilder("vehicleSearchCriteria", builder => builder.allFields(true))
      .build(),
    isUniqueName: qb
      .query("isUniqueName")
      .args<UniqueNameArgs>("UniqueNameInput", true)
      .PrimitiveResult<Boolean>()
      .build(),
    changePersonalData: qb
      .mutation("changeAccountPersonalData")
      .args<ChangeAccountPersonalDataArgs>("ChangeAccountPersonalDataInput", true)
      .extractArgFields("id", "firstname", "lastname", "title", "email", "phone", "vehicleSearchCriteria")
      .Result.allFields(true)
      .build(),
    // GoogleApiTest
    checkAddress: qb
      .query("checkAddress")
      .args<CheckAddressArgs>("CheckAddressInput", true)
      .Result.customType<CheckAddressResult>()
      .selectFields("street", "zip", "city", "state", "stateCode", "country", "countryCode", "formatted_address")
      .subFields("location", "coordinates")
      .build()
  }))
  .build();

export const userInfoGraphQuerySet = buildGraphCrudQuerySet(
  UserInfoDataKit.queries.getAccount,
  UserInfoDataKit.queries.changePersonalData
);
