import * as React from "react";
import { Subject, Subscription } from "rxjs";
import { cloneDeep } from "lodash";
import {
  TransactionManager,
  Transaction,
  LifeCycleUpdater,
  ExclusiveTransaction,
  TransactionManagerChangeArgs
} from "./transactions";
import { AnyObject } from "../controls/masterDetail/MasterDetailContext";
import { useErrorBoundary } from "./ErrorBoundary";
import { useForceUpdate, useServices, ClientException, IServicesAccessor } from "@emibee/lib-app-common";

/**
 * Todos:
 * multi root mapping
 * root property versions
 *
 */

type Reducer<S extends AnyData, A extends DataStoreAction> = (prevState: S, action: A) => S;
type ReducerState<R extends Reducer<any, any>> = R extends Reducer<infer S, any> ? S : never;
type ReducerAction<R extends Reducer<any, DataStoreAction>> = R extends Reducer<any, infer A> ? A : never;
type Dispatch<A> = (value: A) => void;

export interface DataStoreReducerArg<R, A, S = void> {
  root: R;
  scoped: S;
  action: A; //DataStoreAction<R & S, A>
}

export interface DataStoreAction {
  property: string | string[];
  oldIndex?: number;
  newIndex?: number;
  deleteItems?: boolean;
  allowMerge?: boolean;
  newData?: boolean;
}

export interface RootChangeAction {
  property: string | string[];
  bag: StoreDataBag;
  prevBag: StoreDataBag;
}

export interface PropagateAction extends DataStoreAction {
  element: AnyData;
  refreshOnly?: boolean;
}
export interface ArrayAction extends DataStoreAction {
  affectedElements?: AnyData[];
  affectedIndexes?: number[];
}

export interface IDataStoreLink2<T, K extends keyof T, E = any> {
  property: K;
  scope?: string;
  propagate: (changedData: T[K], oldData?: T[K]) => void;
  data: Extract<T[K], E>;
}
export interface IDataStoreLink<T, K extends keyof T, E = any> {
  property?: string;
  scope: string;
  propagate: (
    data: T[K],
    lc?: IterableIterator<LifeCycleBag>,
    index?: number,
    newData?: boolean,
    refreshOnly?: boolean,
    reloadOnly?: boolean
  ) => void;
  propagateLifeCycleChange: (prevData: AnyObject, data?: AnyObject, property?: string[]) => void;
  data: (index?: number) => Extract<T[K], E>;
  rootData: () => any;
  arrayElement?: boolean;
  store: IDataStore;
}

class DataStoreLink<T, K extends keyof T, E = any> implements IDataStoreLink<T, K> {
  //readonly scopeProperty?: string;
  constructor(
    readonly store: IDataStore,
    readonly scope: string,
    readonly _updateLifeCycle: LifeCycleUpdater,
    readonly property?: string,
    readonly arrayElement?: boolean //rootMapping?: DataStoreRootPropertyMapping<T[K]>
  ) {
    // this.scopeProperty = rootMapping && rootMapping.scoped;
  }

  // public get data() {
  //     return this._store.getData(this.scope)[this.property];
  // }

  public data(index?: number) {
    if (index === undefined && this.arrayElement)
      throw new ClientException("DataLink: Retrieve array element without index.");

    let data = this.store.getData(this.scope);
    data = this.property ? data[this.property] : data;
    if (Array.isArray(data)) {
      if (this.arrayElement) return data[index!];
    } else if (this.arrayElement) throw new ClientException("DataLink: Retrieve array element without array.");

    return data;
  }

  public rootData() {
    return this.store.getRootData(this.scope);
  }

  public propagateLifeCycleChange(prevData: AnyObject, data?: AnyObject, property?: string[]) {
    this._updateLifeCycle(prevData, data, property);
  }

  public propagate(
    data: T[K],
    lc?: IterableIterator<LifeCycleBag>,
    index?: number,
    newData?: boolean,
    refreshOnly?: boolean,
    reloadOnly?: boolean
  ) {
    if (reloadOnly) {
      this.store.triggerRefresh();
    } else {
      let currentData = this.store.getData(this.scope);
      let currentValue = this.property ? currentData[this.property] : currentData;
      if (this.arrayElement && (Array.isArray(currentValue) || currentValue === undefined || currentValue === null)) {
        currentValue = !currentValue ? [] : currentValue;
        let newArray: any[];
        if (newData) {
          newArray = [data].concat(currentValue);
        } else {
          if (index === undefined)
            throw new ClientException("DataStoreLink: Cannot propagate changed array element without index.");
          newArray = [...currentValue];
          newArray[index] = data;
        }

        if (this.property) {
          currentData = { ...currentData, [this.property]: newArray };
        }

        this.store.setData(currentData, this.scope, {
          property: this.property || [],
          newIndex: index,
          oldIndex: newData ? undefined : index,
          newData,
          refreshOnly,
          element: data
        } as PropagateAction);

        // update lifecycle
        lc && this.store.mergeLifeCycleState(lc);
      } else throw new Error("propagate Not supported");
    }
  }
}

interface DataStoreRootPropertyMapping<S> {
  root: string | string[];
  scoped?: Extract<keyof S, string>;
}
interface DataStoreStatus {
  initial: boolean;
  lifeCycle: boolean;
  reinit: boolean;
}
export interface IDataStore {
  observeChanges: (observer: (args: DataStoreChangeArgs) => void, scope?: string, undo?: boolean) => Subscription;
  observeDirty: (observer: (args: TransactionManagerChangeArgs) => void) => Subscription;
  register: <S extends AnyData, A extends DataStoreAction>(
    reducer: Reducer<S, A>,
    scope: string,
    rootMapping?: DataStoreRootPropertyMapping<any>
  ) => ScopedDataStore<S, A>;

  unregister: (scope: string) => void;
  init: (data: AnyData, scope: string, allowReInit?: boolean) => void;
  getData: (scope?: string) => any;
  getRootData: (scope: string) => any;
  getParentData: () => any;
  setData: (data: AnyData, scope: string, action: DataStoreAction, undo?: UndoAction) => void;
  setRootData: (data: AnyData, property: string | string[]) => void;
  propagateUndo: (data: StoreDataBag, scope: string, action: any, undo: boolean) => void;
  propagateDataLink: (
    scope: string,
    index?: number,
    newData?: boolean,
    refreshOnly?: boolean,
    reloadOnly?: boolean
  ) => void;
  status: (scope?: string) => DataStoreStatus;
  nestedStore: (
    link: IDataStoreLink<any, any>,
    index?: number,
    initializer?: (data: any, store: IDataStore) => any,
    lc?: boolean,
    idCol?: string
  ) => IDataStore | Promise<IDataStore>;
  linkData: <T, K extends Extract<keyof T, string>>(property: K, scope?: string) => IDataStoreLink<T, K>;
  getLifeCycleState: (data: AnyObject, id?: any) => AnyObject;
  tryGetLifeCycleBag: (data: AnyObject, id?: any) => LifeCycleBag | undefined;
  requestExclusiveTransaction: (name: string) => ExclusiveTransaction;
  reInitialize: (args?: any) => Promise<void>;
  mergeLifeCycleState: (lc: IterableIterator<LifeCycleBag>) => void;
  triggerRefresh: () => void;
  local?: boolean;
  dataPending: boolean;
  transactionManager: TransactionManager<TransactionPayload>;
  servicesAccessor: IServicesAccessor;
}

export interface ScopedDataStore<S, A> {
  readonly scope: string;
  init: (initialState: S) => void;
  status: () => DataStoreStatus;
  change: (action: A) => void;
  data: () => S | undefined;
  dispose: () => void;
  store: IDataStore;
  disposed: () => boolean;
  linkData: <K extends Extract<keyof S, string>>(property: K, arrayElement?: boolean) => IDataStoreLink<S, K>;
  getLifeCycleState: (data: AnyObject) => AnyObject;
  tryGetLifeCycleBag: (data: AnyObject, id?: any) => LifeCycleBag | undefined;
}

// export interface RHDataStore<S, A> extends ScopedDataStore<S, A> {
//     init: (rootData: any, localData?: any) => void;
// }

export interface DataStoreChangeArgs {
  scopes: string[];
  data: StoreDataBag;
  action: DataStoreAction;
  init?: boolean;
  undo?: UndoAction;
}
interface TransactionPayload {
  scope: string;
  data: StoreDataBag;
  action: DataStoreAction;
}

export interface RootChangeArgs {
  transaction: Transaction<TransactionPayload>;
  scopes: string[];
  prevData?: StoreDataBag;
  newData: StoreDataBag;
  action: DataStoreAction;
  undo?: UndoAction;
  updateLf: LifeCycleUpdater;
}

type AnyData = AnyObject | AnyObject[];
export enum UndoAction {
  undo,
  redo,
  rollback,
  ignore
}
export interface StoreDataBag {
  v: number;
  vMax: number;
  data?: AnyData;
}
interface LifeCycleBag {
  current: AnyObject;
  oldId?: any;
}
type Initializer = (args?: any) => Promise<AnyData>;
export class SimpleDataStore implements IDataStore {
  public readonly transactionManager: TransactionManager<TransactionPayload>;
  private _rootChangeHandler?: (args: RootChangeArgs) => void;
  private _lf = new Map<any, LifeCycleBag>();
  private _scopes = new Map<string, StoreDataBag | undefined>();
  private _initial = new Map<string, StoreDataBag | undefined>();
  private _maps = new Map<string, DataStoreRootPropertyMapping<any> | undefined>();
  private _subject = new Subject<DataStoreChangeArgs>();
  private _nestedInitializer: undefined | Initializer;
  private _dataPending = false;
  private _reInitialized = false;
  private _lcChanged = false;
  private _lcPropagateDisabled = false;

  public static ROOT_SCOPE = "_root_";
  static ROOT_HANDLER_SCOPE = "_rh_";

  constructor(
    readonly servicesAccessor: IServicesAccessor,
    readonly local?: boolean,
    private _link?: IDataStoreLink<any, any>,
    private _rootInitializer?: Initializer,
    private _idCol = "id"
  ) {
    this._scopes.set(SimpleDataStore.ROOT_SCOPE, undefined);

    this.transactionManager = new TransactionManager<TransactionPayload>(servicesAccessor);
  }

  public get dataPending() {
    return this._dataPending;
  }

  public observeChanges(observer: (args: DataStoreChangeArgs) => void, scopeSub?: string, undoSub = true) {
    console.log("SimpleDataStore.observeChanges.subscribe", scopeSub);

    return this._subject.subscribe({
      next: args => {
        if (
          (args.undo === undefined || scopeSub || (undoSub && args.undo !== UndoAction.ignore)) &&
          (!scopeSub || args.scopes.includes(scopeSub))
        ) {
          observer(args);
        }
      }
    });
  }

  public observeDirty(observer: (args: TransactionManagerChangeArgs) => void) {
    if (this._rootChangeHandler) {
      return this.transactionManager.observeChanges(observer);
    } else if (this._link) {
      return this._link.store.transactionManager.observeChanges(observer);
    } else throw new Error("observeDirty without rootchange-handler");
  }

  public triggerRefresh() {
    this._scopes.forEach((data, scope) => {
      data &&
        this._subject.next({
          scopes: [scope],
          data,
          action: { property: "" },
          undo: UndoAction.ignore
        });
    });
  }

  private _addMap(scope: string, rootMapping?: DataStoreRootPropertyMapping<any>) {
    rootMapping &&
      this._maps.forEach((v, k) => {
        if (v && !Array.isArray(v.root)) {
          if (!rootMapping.root || v.root === rootMapping.root)
            // todo support multi prop mapping
            throw new ClientException(
              `Register scope '${scope}' failed: Scope '${k}' already defined a rootMapping on the same data.`
            );
        }
      });
    this._maps.set(scope, rootMapping);
  }

  private _getMap(scope: string, action: DataStoreAction) {
    // todo: support multi prop mapping and multiprop actions
    const map = this._maps.get(scope);
    if (map && (!map.scoped || action.property === map.scoped)) return map;
  }

  public register<S extends AnyData, A extends DataStoreAction>(
    reducer: Reducer<S, A>,
    scope: string,
    rootMapping?: DataStoreRootPropertyMapping<any>,
    undo?: UndoAction
  ): ScopedDataStore<S, A> {
    if (this._scopes.has(scope))
      throw new ClientException(`Register scope failed: Scope '${scope}' already registered.`);

    this._scopes.set(scope, undefined);
    this._addMap(scope, rootMapping);
    return this._buildLocalStore(reducer, scope, undo);
  }
  public registerRootHandler<S extends AnyData, A extends DataStoreAction, P>(
    reducer: Reducer<S, A>,
    handler: (args: RootChangeArgs, store: ScopedDataStore<S, A>) => Promise<void>,
    rootInitializer: (args?: P) => Promise<any>,
    initializeArgs?: P,
    nestedInitializer?: Initializer
  ): ScopedDataStore<S, A> {
    const lds = this.register(reducer, SimpleDataStore.ROOT_HANDLER_SCOPE, undefined, UndoAction.ignore);

    this._rootInitializer = rootInitializer;
    this._nestedInitializer = nestedInitializer;

    // lds.init = (rootData: any, localData?: any, allowReInit?: boolean) => {
    //     const reinit = this._getBag() !== undefined;
    //     this._init(rootData, undefined, reinit);

    //     localData && this.init(localData, SimpleDataStore.ROOT_HANDLER_SCOPE, allowReInit);
    //     reinit && allowReInit && this._reInit(SimpleDataStore.ROOT_HANDLER_SCOPE)
    // }

    this._rootChangeHandler = (args: RootChangeArgs) => handler(args, lds);
    this.reInitialize(initializeArgs);
    return lds;
  }

  public registerRootInitializer<S extends AnyData, A extends DataStoreAction, P>(
    reducer: Reducer<S, A>,
    rootInitializer: (args?: P) => Promise<any>,
    initializeArgs?: P
  ) {
    const lds = this.register(reducer, SimpleDataStore.ROOT_HANDLER_SCOPE, undefined, UndoAction.ignore);

    this._rootInitializer = rootInitializer;

    this.reInitialize(initializeArgs);
    return lds;
  }

  public reInitialize(args?: any) {
    if (!this._rootInitializer) throw new Error("re-initialize without rootInitializer");
    this._dataPending = true;
    return this._rootInitializer(args)
      .then(initData => {
        this._dataPending = false; // before init for notify
        this._reInit(initData);
      })
      .finally(() => {
        this._dataPending = false;
      });
  }

  public unregister(scope: string) {
    this._scopes.delete(scope);
    this._maps.delete(scope);
    this._initial.delete(scope);
  }

  private _reInit(data: AnyData) {
    this._init(data, undefined, true);
    this._reboundRootChange(undefined, true);
    this._reInitialized = true;
    // this._maps.forEach( (v, k) => {
    //     if (v ) {
    //         let root = this.getData();
    //         let scoped = this.getData(k);
    //         if (!Array.isArray(root)) {
    //             root = typeof v.root === "string" && root ? root[v.root] : root;
    //         } //else throw new Error("Array as root not supported");
    //         if (v.scoped) {
    //             if (!scoped || Array.isArray(scoped))
    //                 throw new Error("Array or undefined in scoped reinit");
    //             scoped[v.scoped] = root;
    //         } else {
    //             scoped = root;
    //         }
    //         this._init(scoped as AnyData,k);
    //     }

    // });
  }

  public init(data: AnyData, scope: string, allowReInit?: boolean) {
    if (!allowReInit && this._getBag(scope) !== undefined) throw new Error(`Scope '${scope}' already initialized.`);

    const bag = this._init(data, scope);
    // no rootMapping within init phase due to lc
    // const rootMapping = this._maps.get(scope);
    // if (rootMapping) {
    //     const rootData = this._buildRootValue(bag, rootMapping);
    //     this._initBag(rootData);
    // }

    this._subject.next({
      scopes: [scope],
      data: bag,
      action: { property: [] },
      init: true
    });
  }

  private _init(initData: AnyData, scope = SimpleDataStore.ROOT_SCOPE, reinit = false, changedData?: AnyData) {
    const initBag = { data: initData, v: 0, vMax: 0 };
    const changedBag = changedData && { data: changedData, v: 1, vMax: 1 };
    this._initBag(initBag, scope, reinit, changedBag);
    return changedBag || initBag;
  }
  private _initBag(
    initBag: StoreDataBag,
    scope = SimpleDataStore.ROOT_SCOPE,
    reinit = false,
    changedBag?: StoreDataBag
  ) {
    if (reinit || !this._initial.has(scope)) {
      this._initial.set(scope, initBag);
      if (scope === SimpleDataStore.ROOT_SCOPE) this._pushRoot(changedBag || initBag);
      else this._scopes.set(scope, changedBag || initBag);
    }
  }

  public status(scope = SimpleDataStore.ROOT_SCOPE): DataStoreStatus {
    return {
      initial: this.getBag(scope).v === 0,
      lifeCycle: this._lcChanged,
      reinit: this._reInitialized
    };
  }

  public getParentData() {
    return this._link && this._link.rootData();
  }

  public getData(scope?: string) {
    return this.getBag(scope).data;
  }

  public getBag(scope = SimpleDataStore.ROOT_SCOPE): StoreDataBag {
    const bag = this._getBag(scope);
    if (!bag) throw new Error(`Scope '${scope}' not initialized.`);
    return bag;
  }
  private _getBag(scope = SimpleDataStore.ROOT_SCOPE) {
    if (!this._scopes.has(scope)) {
      throw new Error(`Scope '${scope}' not registered.`);
    }
    return this._scopes.get(scope);
  }

  public getRootData(scope: string): any {
    const root = this.getData();
    const rootMapping = this._maps.get(scope);
    if (root && rootMapping && rootMapping.root && !Array.isArray(root)) {
      if (Array.isArray(rootMapping.root)) {
        return rootMapping.root.reduce((p, c) => {
          p[c] = root[c];
          return p;
        }, {} as any);
      } else return root[rootMapping.root];
    } else return root;
  }

  private _bagEquals(bag1: StoreDataBag, bag2: StoreDataBag, property?: string) {
    if (bag1.v !== undefined && bag2.v !== undefined) {
      return bag1.v === bag2.v;
    } else if (property) {
      const p1 = !Array.isArray(bag1.data) && bag1.data !== undefined && bag1.data[property];
      const p2 = !Array.isArray(bag2.data) && bag2.data !== undefined && bag2.data[property];
      return p1 === p2;
    } else {
      return bag1.data === bag2.data;
    }
  }

  public setData(data: AnyData, scope: string, action: DataStoreAction, undo?: UndoAction) {
    const prevData = this.getBag(scope);
    this.setBag(SimpleDataStore._buildBag(data, prevData), scope, action, undo, prevData);
  }
  public setBag(
    bag: StoreDataBag,
    scope: string,
    action: DataStoreAction,
    undo?: UndoAction,
    prevBag?: StoreDataBag,
    removeSelfScopeFromNotify = true
  ) {
    if (!this._scopes.has(scope)) throw new ClientException(`Scope '${scope}' not registered.`);

    // handle direkt root changes
    if (scope === SimpleDataStore.ROOT_SCOPE) {
      const a = action as RootChangeAction;
      if (undo === UndoAction.undo) return this._setRootData(a.prevBag, scope, { root: [] }, action, a.bag, undo);
      else if (undo === UndoAction.redo)
        return this._setRootData(a.bag, scope, { root: a.property }, action, a.prevBag, undo);
      else throw new ClientException(`RootChange not supported with setData and UndoAction: ${undo}.`);
    }

    prevBag = prevBag || this.getBag(scope);
    // fix vMax
    if (prevBag.vMax > bag.vMax) {
      bag.vMax = prevBag.vMax;
    }
    //const undo = storeAction !== undefined ? (storeAction === StoreAction.undo ? true : storeAction === StoreAction.redo) : undefined;
    // only update if mapped root value has changed
    const scopes = [scope]; //removeSelfScopeFromNotify ? [] : [scope];
    const rootMapping = this._getMap(scope, action);
    if (rootMapping) {
      // todo: support multi prop mapping
      const prevDataScope = this.getBag(scope);
      if (!this._bagEquals(bag, prevDataScope, rootMapping.scoped)) {
        this._setRootData(bag, scope, rootMapping, action, prevBag, undo);
        // const prevData = this._scopes.get(SimpleDataStore.ROOT_SCOPE);
        // const newData = this._buildRootValue(bag, rootMapping, prevData);

        // // also RootHandler can update root scope e.g. cid
        // if (scope !== SimpleDataStore.ROOT_HANDLER_SCOPE && undo !== UndoAction.rollback && this._rootChangeHandler && !(action as PropagateAction).refreshOnly) {
        //     const transaction = this.transactionManager.enqueue({data: prevBag,scope,action}, t => this.setBag(t.payload.data, t.payload.scope, t.payload.action, UndoAction.rollback), undo === UndoAction.undo ? prevBag.v : bag.v);
        //     transaction && this._rootChangeHandler({transaction, scopes, prevData, newData, action: {...action, property: rootMapping.root}, undo, updateLf: this._updateLifeCycle.bind(this)});
        // }

        // this._pushRoot(newData, scope);
        scopes.push(SimpleDataStore.ROOT_SCOPE);
      }
    }

    this._scopes.set(scope, bag);
    console.log("SimpleDataStore.setData", undo, bag, scope, action, undo, this._scopes);
    this._subject.next({ scopes, data: bag, action, undo });
  }

  setRootData(data: AnyData, property: string | string[]) {
    const scope = SimpleDataStore.ROOT_SCOPE;
    const prevBag = this.getBag(scope);
    const bag = SimpleDataStore._buildBag(data, prevBag);
    const action: RootChangeAction = {
      property,
      bag,
      prevBag
    };
    this._setRootData(bag, scope, { root: property }, action, prevBag);

    console.log("SimpleDataStore.setRootData", bag, action, this._scopes);
    this._subject.next({ scopes: [scope], data: bag, action });
  }

  private _setRootData(
    bag: StoreDataBag,
    scope: string,
    rootMapping: DataStoreRootPropertyMapping<any>,
    action: DataStoreAction,
    prevBag: StoreDataBag,
    undo?: UndoAction
  ) {
    const prevData = this._scopes.get(SimpleDataStore.ROOT_SCOPE);
    const newData = this._buildRootValue(bag, rootMapping, prevData);

    // also RootHandler can update root scope e.g. cid
    if (
      scope !== SimpleDataStore.ROOT_HANDLER_SCOPE &&
      undo !== UndoAction.rollback &&
      this._rootChangeHandler &&
      !(action as PropagateAction).refreshOnly
    ) {
      const transaction = this.transactionManager.enqueue(
        { data: prevBag, scope, action },
        t => this.setBag(t.payload.data, t.payload.scope, t.payload.action, UndoAction.rollback),
        undo === UndoAction.undo ? prevBag.v : bag.v
      );
      transaction &&
        this._rootChangeHandler({
          transaction,
          scopes: [scope],
          prevData,
          newData,
          action: { ...action, property: rootMapping.root },
          undo,
          updateLf: this._updateLifeCycle.bind(this)
        });
    }

    this._pushRoot(newData, scope);
  }

  public requestExclusiveTransaction(name: string) {
    return this.transactionManager.requestExclusiveTransaction(name, this._updateLifeCycle.bind(this));
  }

  private _pushRoot(bag: StoreDataBag, sourceScope?: string) {
    this._scopes.set(SimpleDataStore.ROOT_SCOPE, bag);

    // LifeCycle
    if (bag.data) {
      const d = Array.isArray(bag.data) ? bag.data : [bag.data];
      const idCol = this._idCol;
      d.forEach(e => {
        const id = e[idCol];
        // Mb: removed, because there could be sub objects without ids (eg. Properties of EnvModel)
        // if (id === undefined) {
        //     throw new Error("invalid data. No id");
        // } else
        if (id !== undefined && !this._lf.has(id)) {
          this._lf.set(id, { current: e });
        }
      });
    }

    // rebound
    sourceScope && this._reboundRootChange(sourceScope);
  }

  public getLifeCycleState(data: AnyObject, id?: any) {
    const bag = this.tryGetLifeCycleBag(data, id);
    if (!bag) {
      throw new Error("LifeCycle-Bag not found: " + id);
    }
    return bag.current;
  }

  public tryGetLifeCycleBag(data: AnyObject, id?: any): LifeCycleBag | undefined {
    // lc data must be maintained at root level
    if (!this._rootChangeHandler && this._link) {
      return this._link.store.tryGetLifeCycleBag(data, id);
    } else {
      id = id || data[this._idCol];
      return this._lf.get(id);
    }
  }

  public mergeLifeCycleState(lc: IterableIterator<LifeCycleBag>) {
    for (const bag of lc) {
      this._updateLifeCycle(bag.current, bag.current);
    }
  }

  private _updateLifeCycle(prevData: AnyObject, data?: AnyObject, property?: string[]) {
    const id = prevData[this._idCol];
    console.log("SimpleDataStore.updateLifeCycle", id, data, property);
    const bag = this._lf.get(id) || ({} as LifeCycleBag);
    // mb: should also be usable as an insert (Case: first EnvModel)
    //if (!bag) throw new Error("LifeCycle-Bag not found: " + id);

    if (data) {
      const dataId = data[this._idCol];
      if (dataId !== id) {
        if (bag.oldId !== undefined && bag.oldId !== id)
          throw new Error("LifeCycle-Bag already has oldId: " + bag.oldId);
        bag.oldId = id;
        this._lf.set(dataId, bag);
      }
      if (property && bag.current) {
        // root push
        let root = this.getData();
        if (Array.isArray(root)) {
          root = root.find(e => e.id === id || (bag.oldId && e.id === bag.oldId));
        }
        property.forEach(p => {
          bag.current[p] = data[p];
          if (root) {
            (root as any)[p] = data[p];
          }
        });
      } else {
        bag.current = data;
      }
    } else {
      if (bag.oldId) {
        this._lf.delete(bag.oldId);
        this._lf.delete(id);
      }
    }
    this._lcChanged = true;

    if (this._link) this._link.propagateLifeCycleChange(prevData, data, property);
  }

  private _reboundRootChange(exceptScope?: string, init?: boolean) {
    this._maps.forEach((v, k) => {
      if (v && (!exceptScope || k !== exceptScope)) {
        let root = this.getData();
        let scoped = this.getBag(k);
        if (!Array.isArray(root)) {
          root = typeof v.root === "string" && root ? root[v.root] : root;
        } //else throw new Error("Array as root not supported");
        if (v.scoped) {
          if (!scoped.data || Array.isArray(scoped.data)) throw new Error("Array or undefined in scoped reinit");
          scoped.data[v.scoped] = root;
        } else {
          scoped.data = root;
        }
        this._subject.next({
          scopes: [k],
          data: scoped,
          action: { property: "" },
          undo: UndoAction.ignore,
          init
        });
      }
    });

    const data = this.getBag();
    if (init && data) {
      this._subject.next({
        scopes: [SimpleDataStore.ROOT_SCOPE],
        data,
        action: { property: "" },
        undo: UndoAction.ignore,
        init
      });
    }
  }

  public propagateUndo(data: StoreDataBag, scope: string, action: any, undo: boolean) {
    this.setBag(data, scope, action, undo ? UndoAction.undo : UndoAction.redo);
    // this._scopes.set(scope, data);
    // this._subject.next({scopes: [scope], data, undo: true});
  }

  public propagateDataLink(
    scope: string,
    index?: number,
    newData?: boolean,
    refreshOnly?: boolean,
    reloadOnly?: boolean
  ) {
    if (!this._link) throw new ClientException("Cannot propagateDataLink: No link found.");
    this._link.propagate(
      this.getData(),
      this._lcPropagateDisabled ? undefined : this._lf.values(),
      index,
      newData,
      refreshOnly,
      reloadOnly
    );
  }

  public linkData<T, K extends Extract<keyof T, string>>(property: K, scope?: string): IDataStoreLink<T, K> {
    return new DataStoreLink<T, K>(
      this,
      scope || SimpleDataStore.ROOT_SCOPE,
      this._updateLifeCycle.bind(this),
      property
    );
  }
  public nestedStore(
    link: IDataStoreLink<any, any>,
    index?: number,
    initializer?: (data: any, store: IDataStore) => any,
    lc = true,
    idCol?: string
  ) {
    const store = new SimpleDataStore(this.servicesAccessor, this.local, link, this._nestedInitializer, idCol);

    // pull data
    let data: any;
    if (!link.arrayElement || index !== undefined) data = link.data(index);

    const initStore = (d: any, cd: any) => {
      // set init
      store._init(d || {}, undefined, true, cd);

      // pull lifecycle
      // only root data should provide lc
      // update mb: if (d && !link.property && (link.arrayElement || index !== undefined))
      //  -> link.property is "rows" in first detailView,
      //  -> why disable propagation? because data could not be lc managed
      //  -> check for rootchange handler
      if (d && this._rootChangeHandler && lc) {
        const id = d[store._idCol];
        const bag = this.tryGetLifeCycleBag(d, id);
        if (bag) store._updateLifeCycle(d, bag.current);
      } else {
        store._lcPropagateDisabled = true;
      }

      return store;
    };

    if (initializer) {
      // pre-register lc to allow initializer to update it
      if (data) {
        store._init(data || {}, undefined, true);
      }
      const initData = initializer(data ? { ...data } : undefined, store);

      if (initData instanceof Promise) {
        return initData.then(initD => {
          return initStore(data || initD, data ? initD : undefined);
        });
      } else return initStore(data || initData, data ? initData : undefined);
    } else return initStore(data, undefined);
  }

  private _buildRootValue(
    bag: StoreDataBag,
    rootMapping: DataStoreRootPropertyMapping<any>,
    prevBag?: StoreDataBag
  ): StoreDataBag {
    let mappedData =
      rootMapping.scoped && !Array.isArray(bag.data) ? bag.data && bag.data[rootMapping.scoped] : bag.data;
    if (rootMapping.root && !Array.isArray(rootMapping.root)) {
      // todo: support
      const rootBag = this._scopes.get(SimpleDataStore.ROOT_SCOPE);
      const rootData = (rootBag && rootBag.data) || {};
      mappedData = { ...rootData, [rootMapping.root]: mappedData };
    }
    return SimpleDataStore._buildBag(mappedData, prevBag);
  }

  private _buildLocalStore<S extends AnyData, A extends DataStoreAction>(
    reducer: Reducer<S, A>,
    scope: string,
    undo?: UndoAction
  ): ScopedDataStore<S, A> {
    // const rootChange = (prevData: any, newData: any) => {
    //     return !rootMapping!.scoped || prevData[rootMapping!.scoped] !== newData[rootMapping!.scoped];
    // }
    let disposed = false;
    const store = this;
    return {
      scope,
      init: (initialState: S) => store.init(initialState, scope),
      status: () => store.status(scope),
      data: () => store.getData(scope) as any,
      change: (action: A) => {
        const prevData = store.getData(scope);
        const newData = reducer(prevData as any, action);
        if (prevData !== newData) store.setData(newData, scope, action, undo);
      },
      dispose: () => {
        disposed = true;
        store.unregister(scope);
      },
      disposed: () => disposed,
      store,
      linkData: <K extends Extract<keyof S, string>>(property: K, arrayElement?: boolean) => {
        return new DataStoreLink<S, K>(store, scope, store._updateLifeCycle.bind(store), property, arrayElement);
      },
      getLifeCycleState: (data: AnyObject) => store.getLifeCycleState(data),
      tryGetLifeCycleBag: (data: AnyObject) => store.tryGetLifeCycleBag(data)
    };
  }

  static _buildBag(data: AnyData, prevBag?: StoreDataBag): StoreDataBag {
    const v = prevBag ? prevBag.vMax + 1 : 0;
    return { data, v, vMax: v };
  }
}

interface UndoDataEntry {
  scope: string;
  action: DataStoreAction;
  data: StoreDataBag;
}

// const UM_DEFAULT_SCOPE = "_default_";

export class UndoManager {
  private _index = -1;
  private _initValue = {} as any;
  private _store = [] as UndoDataEntry[];
  private _dsSubscription: Subscription;
  private _observerable = new Subject<void>();
  private _lastChange: { property?: string | string[]; scope: string } | undefined;

  constructor(private readonly _dataStore: IDataStore) {
    this._dsSubscription = _dataStore.observeChanges(({ scopes, data, action, init, undo }) => {
      console.log("UndoManager.observedChange", action, undo);

      if (undo !== undefined) {
        if (undo === UndoAction.rollback) {
          // check version if rollback of undo / redo
          if (this.canRedo && this._store[this._index + 1].data.v === data.v) this._index++;
          else this._index--;
          this._lastChange = undefined;
        }
        // else ignore
      } else {
        // scopes[0]: will return scope name if there is any (root will be second)
        if (init) {
          //if (this._index > -1 || this._store.length > 1) throw new ClientException("Init DataStore after first changes not supported.");
          this._index = -1;
          this._store = [];
          this._lastChange = undefined;
          this._initValue[scopes[0]] = data;
        } else {
          if (!this._handleMerge(scopes[0], data, action)) this._add(scopes[0], action, data);
        }
      }
    }, undefined);
  }

  public observeChanges(observer: () => void) {
    console.log("UndoManager.observeChanges");
    return this._observerable.subscribe({
      next: () => observer()
    });
  }

  private _handleMerge(scope: string, data: any, action: DataStoreAction) {
    // todo: support multi property actions
    if (
      action &&
      action.allowMerge &&
      this._lastChange &&
      this._lastChange.scope === scope &&
      this._lastChange.property &&
      this._lastChange.property === action.property
    ) {
      this._current.data = data;
      return true;
    } else if (action) {
      this._lastChange = { scope, property: action.property };
    }
    return false;
  }

  private _add(scope: string, action: any, data: any) {
    // trim
    this._store.splice(this._index + 1, this._store.length - (this._index + 1));
    this._store.push({ scope, data, action });
    this._index++;
    console.log("UndoStore.add", this._store);
    this._observerable.next();
  }

  private get _current() {
    return this._store[this._index];
  }

  private _propagateUndoChange(scope: string, action: any, undo: boolean) {
    if (this._index === -1) {
      this._dataStore.propagateUndo(this._initValue[scope], scope, action, undo);
    } else {
      // find last value
      let idx = this._index;
      let entry: UndoDataEntry;
      do {
        entry = this._store[idx];
        idx--;
      } while (entry.scope !== scope && idx > -1);

      if (entry.scope === scope) {
        this._dataStore.propagateUndo(entry.data, scope, action, undo);
      } else {
        this._dataStore.propagateUndo(this._initValue[scope], scope, action, undo);
      }
    }
    this._observerable.next();
  }

  public get canUndo() {
    return this._index > -1;
  }
  public undo() {
    if (this.canUndo) {
      const scope = this._current.scope;
      const action = this._current.action;
      this._index--;
      this._lastChange = undefined;
      this._propagateUndoChange(scope, action, true);
    }
  }
  public get canRedo() {
    return this._index < this._store.length - 1;
  }
  public redo() {
    if (this.canRedo) {
      this._index++;
      const scope = this._current.scope;
      this._propagateUndoChange(scope, this._current.action, false);
    }
  }

  public dispose() {
    this._dsSubscription.unsubscribe();
  }
}

export interface UndoManagerContextData {
  undoManager?: UndoManager;
}

export const UndoManagerContext = React.createContext<UndoManagerContextData>({} as any);
UndoManagerContext.displayName = "UndoManagerContext";

export interface DataStoreContextData {
  dataStore: IDataStore;
}

export const DataStoreContext = React.createContext<DataStoreContextData>({} as any);
DataStoreContext.displayName = "DataStoreContext";

interface UseDataStoreOptions<R extends Reducer<any, any>, I> {
  scope: string;
  rootMapping: DataStoreRootPropertyMapping<ReducerState<R>> | null;
  allowLocalStore?: boolean;
  initialState?: ReducerState<R>;
  initializer?: (arg: I, store: IDataStore) => ReducerState<R>;
  initializerArg?: I;
  observeChanges?: boolean;
  observeUndoChangesOnly?: boolean;
  changeHandler?: (action: ReducerAction<R>, data?: ReducerState<R>) => void;
}
export function useDataStore<R extends Reducer<any, any>, I = never>(
  reducer: R,
  options: UseDataStoreOptions<R, I>
): ScopedDataStore<ReducerState<R>, ReducerAction<R>> | undefined {
  const accessor = useServices();
  const errorBoundary = useErrorBoundary();
  const context = React.useContext(DataStoreContext);
  const { scope, allowLocalStore = true, rootMapping, changeHandler } = options;
  const [store] = React.useState(() => {
    if (!context || !context.dataStore) {
      if (!allowLocalStore) {
        errorBoundary.asyncError(
          new ClientException("useDataStore (allowLocalStore=false): without store in context.")
        );
        return undefined;
      }
      logger.verbose("useDataStore", "Using local store.");
      return new SimpleDataStore(accessor, true) as IDataStore;
    } else {
      return context.dataStore;
    }
  });

  const forceUpdate = useForceUpdate();
  const [localStore] = React.useState(() => {
    if (store) {
      const ls = store.register(reducer, scope, rootMapping || undefined);

      try {
        if (options.initialState !== undefined) {
          ls.init(options.initialState);
        } else if (options.initializer && options.initializerArg !== undefined) {
          ls.init(options.initializer(options.initializerArg, store));
        } else {
          throw new ClientException("No initial data provided.");
        }
      } catch (error) {
        errorBoundary.asyncError(error as any);
        return undefined;
      }

      return ls;
    }
  });

  // unregister scope
  React.useEffect(() => {
    return () => localStore && localStore.dispose();
  }, [localStore]);

  const observeChanges = options.observeChanges;
  const observeUndoChangesOnly = options.observeUndoChangesOnly;
  React.useEffect(() => {
    if (store && (observeChanges || observeUndoChangesOnly)) {
      const sub = store.observeChanges(({ undo, action, data }) => {
        console.log("DS.observeChanges", action);
        if (undo !== undefined || !observeUndoChangesOnly) {
          if (changeHandler) changeHandler(action as any, data.data as any);
          else forceUpdate();
        }
      }, scope);
      return () => sub.unsubscribe();
    }
  }, [store, observeChanges, observeUndoChangesOnly, forceUpdate, scope, changeHandler]);

  return localStore;
}

export function useUndo() {
  const umCtx = React.useContext(UndoManagerContext);
  return umCtx ? umCtx.undoManager : undefined;
}
