"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuctionAccountListTypes = exports.SecurityRole = exports.UserType = exports.ResellerStatus = exports.PersonalTitle = void 0;
var PersonalTitle;
(function (PersonalTitle) {
    PersonalTitle[PersonalTitle["Mr"] = 0] = "Mr";
    PersonalTitle[PersonalTitle["Ms"] = 1] = "Ms";
})(PersonalTitle || (exports.PersonalTitle = PersonalTitle = {}));
var ResellerStatus;
(function (ResellerStatus) {
    ResellerStatus[ResellerStatus["rejected"] = 0] = "rejected";
    ResellerStatus[ResellerStatus["pending"] = 1] = "pending";
    ResellerStatus[ResellerStatus["confirmed"] = 2] = "confirmed";
})(ResellerStatus || (exports.ResellerStatus = ResellerStatus = {}));
// export enum UserStatus {
//   Rejected = 0,
//   Pending = 1,
//   Approved = 2,
// }
var UserType;
(function (UserType) {
    UserType[UserType["System_Admin"] = 100] = "System_Admin";
    UserType[UserType["Admin"] = 80] = "Admin";
    UserType[UserType["Dealer"] = 50] = "Dealer";
    UserType[UserType["Reseller"] = 10] = "Reseller";
})(UserType || (exports.UserType = UserType = {}));
var SecurityRole;
(function (SecurityRole) {
    SecurityRole["User"] = "User";
    SecurityRole["Administrator"] = "Administrator";
    SecurityRole["SysAdmin"] = "SysAdmin";
})(SecurityRole || (exports.SecurityRole = SecurityRole = {}));
var AuctionAccountListTypes;
(function (AuctionAccountListTypes) {
    AuctionAccountListTypes[AuctionAccountListTypes["visitors"] = 0] = "visitors";
    AuctionAccountListTypes[AuctionAccountListTypes["starred"] = 1] = "starred";
})(AuctionAccountListTypes || (exports.AuctionAccountListTypes = AuctionAccountListTypes = {}));
