import { buildRouteWithParams } from "@emibee/lib-app-common";
import { data, Domains } from "../core/textResourceScopes";

export const LegalRoute = buildRouteWithParams<{ page: string }>({
  path: "/legal",
  auth: false,
  resources: {
    lazy: () => import("./LegalPage"),
    localeNamespaces: [data(Domains.common)]
  },
  params: ["page"]
});
